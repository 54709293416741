import { print as printQuery } from "@0no-co/graphql.web";
import { captureException, init } from "@sentry/react";
import { isNotNullish, isNullish } from "fleming-lake/src/utils/nullish";
import { CombinedError, Operation, OperationContext } from "urql";
import { env } from "./env";

export { setUser as setSentryUser } from "@sentry/react";

const FORCE_DEV_LOGGING = true;

const ENABLED = process.env.NODE_ENV === "production" || FORCE_DEV_LOGGING;

export const initSentry = () => {
  if (ENABLED) {
    init({
      release: env.VERSION,
      dsn: env.SENTRY_DSN,
      environment: env.BANKING_URL.includes("preprod")
        ? "preprod"
        : env.BANKING_URL.includes("master")
          ? "master"
          : "prod",
      normalizeDepth: 5,
      ignoreErrors: [/^.*Unauthorized$/gim],
    });
  }
};

const getOperationContextHeaders = (context: OperationContext): Record<string, string> => {
  const { headers } =
    typeof context.fetchOptions === "function"
      ? context.fetchOptions()
      : context.fetchOptions ?? {};

  if (isNullish(headers)) {
    return {};
  }
  if (Array.isArray(headers)) {
    return Object.fromEntries(headers);
  }
  if (headers instanceof Headers) {
    return Object.fromEntries(headers.entries());
  }

  return headers;
};

export const logFrontendError = (exception: Error, extra?: Record<string, unknown>) => {
  captureException(exception, {
    extra,
    tags: { scope: "frontend" },
  });
};

export const logBackendError = (
  { graphQLErrors }: CombinedError,
  { context, query, variables }: Operation,
) => {
  if (graphQLErrors.length === 0) {
    return;
  }

  const headers = getOperationContextHeaders(context);
  const existingHeaders = Object.keys(headers).filter(key => isNotNullish(headers[key]));
  const requestId = headers["x-swan-request-id"];

  graphQLErrors
    .filter(({ message }) => {
      const lowerCased = message.toLowerCase();

      return (
        !lowerCased.includes("unauthenticated") &&
        !lowerCased.includes("unauthorized") &&
        !lowerCased.includes("cannot return null for non-nullable field")
      );
    })
    .forEach(({ message }) => {
      // Update the error message to prepend the requestId

      const error = new Error(isNotNullish(requestId) ? `${requestId} - ${message}` : message);
      error.name = "GraphQLError";
      captureException(error, {
        tags: {
          scope: "backend",
          endpoint: context.url,
        },
        extra: {
          headers: existingHeaders,
          query: printQuery(query),
          requestPolicy: context.requestPolicy,
          suspense: context.suspense,
          variables,
        },
      });
    });
};
