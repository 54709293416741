import { useQuery } from "@swan-io/graphql-client";
import { AsyncData, Result } from "@swan-io/boxed";
import { BorderedIcon } from "fleming-lake/src/components/BorderedIcon";
import { Box } from "fleming-lake/src/components/Box";
import { Fill } from "fleming-lake/src/components/Fill";
import { LakeAlert } from "fleming-lake/src/components/LakeAlert";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { LakeTextInput } from "fleming-lake/src/components/LakeTextInput";
import { Link } from "fleming-lake/src/components/Link";
import { LoadingView } from "fleming-lake/src/components/LoadingView";
import { Separator } from "fleming-lake/src/components/Separator";
import { Space } from "fleming-lake/src/components/Space";
import { Tile } from "fleming-lake/src/components/Tile";
import { WithPartnerAccentColor } from "fleming-lake/src/components/WithPartnerAccentColor";
import {
  backgroundColor,
  breakpoints,
  colors,
  invariantColors,
  spacings,
} from "fleming-lake/src/constants/design";
import { useResponsive } from "fleming-lake/src/hooks/useResponsive";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
// import { parseOperationResult } from "fleming-lake/src/utils/urql";
import { useCallback } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { useForm } from "react-ux-form";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { ProjectLoginPageDocument } from "../graphql/unauthenticated";
import { env } from "../utils/env";
import { getFirstSupportedLanguage, t } from "../utils/i18n";
import { Router } from "../utils/routes";
// import { partnerClient, unauthenticatedClient } from "../utils/urql";
import { validateRequired } from "../utils/validations";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    marginHorizontal: "auto",
    padding: spacings[24],
  },
  clientLogo: {
    height: 25,
    width: "100%",
  },
  tile: {
    paddingHorizontal: spacings[72],
    paddingVertical: spacings[72],
  },
  iconContainer: {
    margin: "auto",
  },
  link: {
    wordBreak: "keep-all",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

const ACCENT_COLOR = "#255988";

const HelpLink = ({ to, children }: { to: string; children: string }) => (
  <LakeText variant="smallRegular" style={styles.link}>
    <Link to={to} style={({ hovered }) => hovered && styles.underline} target="blank">
      {children}
    </Link>
  </LakeText>
);

// We keep swan logic for the future and avoid conflicts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORT_ROOT_URL = `https://support.swan.io/hc/${getFirstSupportedLanguage([
  "en",
  "fr",
  "de",
  "es",
  "it",
])}`;

const FLEMING_SUPPORT_ROOT_URL = `https://support.swan.io/hc/de`;

type LoginContentProps = {
  accentColor: string;
  onLogin: (phone?: string) => void;
  onSignup?: () => void;
};

const LoginContent = ({ onLogin }: LoginContentProps) => {
  const { Field, submitForm } = useForm({
    phone: {
      initialValue: "",
      validate: validateRequired,
    },
  });

  const submitPhone = useCallback(() => {
    submitForm(({ phone }) => {
      onLogin(phone);
    });
  }, [submitForm, onLogin]);
  return (
    <WithPartnerAccentColor color={ACCENT_COLOR}>
      <View style={styles.iconContainer}>
        <BorderedIcon name="phone-regular" size={100} padding={8} color="partner" />
      </View>

      <Space height={32} />

      <LakeLabel
        label={t("demo.phone.label")}
        render={id => (
          <Field name="phone">
            {({ value, onChange, onBlur, error, validating, valid, ref }) => (
              <LakeTextInput
                id={id}
                ref={ref}
                value={value}
                inputMode="tel"
                placeholder="030123456789"
                validating={validating}
                error={error}
                valid={valid}
                onChangeText={onChange}
                onBlur={onBlur}
              />
            )}
          </Field>
        )}
      />

      <LakeButton color="partner" onPress={submitPhone}>
        {t("login.buttonText")}
      </LakeButton>

      <Space height={32} />

      {/*<LakeButton color="gray" mode="secondary" onPress={onSignup}>*/}
      {/*  {t("signup.buttonText")}*/}
      {/*</LakeButton>*/}

      <Separator space={16} />

      <LakeText color={colors.gray[900]} variant="semibold">
        {t("login.needHelp")}
      </LakeText>

      <Space height={8} />
      <HelpLink to={`${FLEMING_SUPPORT_ROOT_URL}-150`}>{t("login.linkHow")}</HelpLink>
      <Space height={8} />

      <HelpLink
        to={`${FLEMING_SUPPORT_ROOT_URL}-150/articles/5490446960797-How-do-I-report-fraud-`}
      >
        {t("login.linkFraud")}
      </HelpLink>
    </WithPartnerAccentColor>
  );
};

export const DemoLoginPage = ({
  projectId,
  message,
}: {
  projectId: string;
  sessionExpired?: boolean;
  message?: string;
}) => {
  const { desktop } = useResponsive(breakpoints.medium);
  const envType = env.APP_TYPE === "LIVE" ? "Live" : "Sandbox";
  const [projectInfos] = useQuery(ProjectLoginPageDocument, { projectId, env: envType });

  const handleSignupPress = useCallback(() => {
    window.location.replace(`${env.ONBOARDING_URL}/onboarding/company/start`);
  }, []);

  const handleDemoPress = useCallback(
    (phoneNumber = "") => {
      const redirectTo = Router.ProjectRootRedirect();
      const params = new URLSearchParams();
      params.set("projectId", projectId);
      params.set("phone", phoneNumber);

      params.set("redirectTo", redirectTo);
      window.location.replace(`/auth/demologin?${params.toString()}`);
    },
    [projectId],
  );

  const LOGO_URI =
    "https://s3.eu-west-1.amazonaws.com/data.swan.io/76525afb-1ddd-4018-8572-0e4cc4d77202/SANDBOX/logo-5e58bdec-f7ff-4060-ac09-dbb6cf7eba4f.png";
  const NAME = "Fleming";

  return match(
      projectInfos.mapOk(({ projectInfoById }) => ({
        accentColor: projectInfoById.accentColor ?? invariantColors.gray,
        name: projectInfoById.name,
        logoUri: projectInfoById.logoUri ?? undefined,
      })),
    )
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ accentColor, name, logoUri }) => {
      return (
        <ScrollView style={styles.base} contentContainerStyle={styles.content}>
          <Box role="banner" alignItems="center">
            {isNotNullish(logoUri) ? (
              <Image
                source={{ uri: logoUri }}
                resizeMode="contain"
                aria-label={name}
                style={styles.clientLogo}
              />
            ) : (
              <Image
                source={{ uri: LOGO_URI }}
                resizeMode="contain"
                aria-label={NAME}
                style={styles.clientLogo}
              />
            )}
          </Box>

          <Fill minHeight={48} />

          {message != null && !message && (
            <>
              <Space height={24} />
              <LakeAlert variant="warning" title={t("login.sessionExpired.title")} />
              <Space height={desktop ? 24 : 48} />
            </>
          )}

          {desktop ? (
            <Tile style={styles.tile}>
              <LoginContent
                accentColor={accentColor}
                onLogin={handleDemoPress}
                onSignup={handleSignupPress}
              />
            </Tile>
          ) : (
            <View>
              <LoginContent accentColor={accentColor} onLogin={handleDemoPress} />
            </View>
          )}

          <Fill minHeight={48} />
          <Space height={12} />

          <LakeText align="center" variant="smallRegular" color={colors.gray[700]}>
            {t("login.disclaimer.swan")}
          </LakeText>
        </ScrollView>
      );
    })
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .otherwise(() => <LoadingView color={colors.gray[400]} style={styles.base} />);
};
