import { useMutation } from "@swan-io/graphql-client";
import { translateError } from "fleming-shared-business/src/utils/i18n";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeHeading } from "fleming-lake/src/components/LakeHeading";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Separator } from "fleming-lake/src/components/Separator";
import { Space } from "fleming-lake/src/components/Space";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { breakpoints, spacings } from "fleming-lake/src/constants/design";
import { showToast } from "fleming-lake/src/state/toasts";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  AccountCountry,
  FlemingPrepareSepaTransactionDocument,
  FlemingUpdatePreparedSepaTransactionDocument,
  InitiateSepaCreditTransfersDocument,
} from "../graphql/partner";
import { encodeDateTime } from "../utils/date";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import {
  Details,
  TransferRegularWizardDetails,
  TransferRegularWizardDetailsSummary,
} from "./TransferRegularWizardDetails";
import { Schedule, TransferRegularWizardSchedule } from "./TransferRegularWizardSchedule";
import {
  Beneficiary,
  TransferWizardBeneficiary,
  TransferWizardBeneficiarySummary,
} from "./TransferWizardBeneficiary";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  container: {
    ...commonStyles.fill,
  },
  header: {
    paddingVertical: spacings[12],
  },
  headerContents: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    maxWidth: 1336,
    marginHorizontal: "auto",
    paddingHorizontal: spacings[96],
  },
  headerTitle: {
    ...commonStyles.fill,
  },
  mobileZonePadding: {
    paddingHorizontal: spacings[24],
    flexGrow: 1,
  },
  contents: {
    flexShrink: 1,
    flexGrow: 1,
    marginHorizontal: "auto",
    maxWidth: 1172,
    paddingHorizontal: spacings[24],
    paddingVertical: spacings[24],
    width: "100%",
  },
  desktopContents: {
    marginVertical: "auto",
    paddingHorizontal: spacings[96],
    paddingVertical: spacings[24],
  },
});

type Step =
  | { name: "Beneficiary"; beneficiary?: Beneficiary }
  | {
      name: "Details";
      beneficiary: Beneficiary;
      details?: Details;
    }
  | { name: "Schedule"; beneficiary: Beneficiary; details: Details };

type Props = {
  onPressClose?: () => void;
  accountCountry: AccountCountry;
  accountId: string;
  accountMembershipId: string;
  name?: string;
  IBAN?: string;
  amount?: string;
  currency?: string;
  label?: string;
  reference?: string;
  canInitiatePayments: boolean;
  userId: string;
  preparedTransferId?: string;
};

export const TransferRegularWizard = ({
  preparedTransferId,
  userId,
  onPressClose,
  accountCountry,
  accountId,
  accountMembershipId,
  name = "",
  IBAN = "",
  amount = "",
  currency = "",
  reference = "",
  label = "",
  canInitiatePayments,
}: Props) => {
  const [initiateTransfers, transfer] = useMutation(InitiateSepaCreditTransfersDocument);
  const [saveTransfers, actualTransfer] = useMutation(FlemingPrepareSepaTransactionDocument);
  const [updateTransfers] = useMutation(FlemingUpdatePreparedSepaTransactionDocument);
  const [step, setStep] = useState<Step>({ name: "Beneficiary" });
  const repeatedBeneficiary: Beneficiary = {
    name,
    iban: IBAN,
  };
  const repeatedDetails: Details = {
    amount: {
      value: amount?.replace(".", ","),
      currency,
    },
    label,
    reference: isNotNullish(preparedTransferId) ? reference : "",
  };
  const initiateTransfer = ({
    beneficiary,
    details,
    schedule,
  }: {
    beneficiary: Beneficiary;
    details: Details;
    schedule: Schedule;
  }) => {
    initiateTransfers({
      input: {
        accountId,
        consentRedirectUrl:
          window.location.origin + Router.AccountTransactionsListRoot({ accountMembershipId }),
        creditTransfers: [
          {
            amount: details.amount,
            label: details.label,
            reference: details.reference,
            ...match(schedule)
              .with({ isScheduled: true }, ({ scheduledDate, scheduledTime }) => ({
                requestedExecutionAt: encodeDateTime(scheduledDate, `${scheduledTime}:00`),
              }))
              .otherwise(({ isInstant }) => ({
                mode: isInstant ? "InstantWithFallback" : "Regular",
              })),
            sepaBeneficiary: {
              name: beneficiary.name,
              save: false,
              iban: beneficiary.iban,
              isMyOwnIban: false, // TODO
            },
          },
        ],
      },
    })
      .mapOk(data => data.initiateCreditTransfers)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ payment }) => {
        const status = payment.statusInfo;
        const params = { paymentId: payment.id, accountMembershipId };
        return match(status)
          .with({ __typename: "PaymentInitiated" }, () => {
            match(preparedTransferId)
              .with(
                P.string,
                preparedTransferId => isNotNullish(preparedTransferId),
                () => {},
              )
              .otherwise(() => {});
            showToast({
              variant: "success",
              title: t("transfer.consent.success.title"),
              description: t("transfer.consent.success.description"),
              autoClose: false,
            });
            Router.replace("AccountTransactionsListRoot", params);
          })
          .with({ __typename: "PaymentRejected" }, () =>
            showToast({
              variant: "error",
              title: t("transfer.consent.error.rejected.title"),
              description: t("transfer.consent.error.rejected.description"),
            }),
          )
          .with({ __typename: "PaymentConsentPending" }, ({ consent }) => {
            window.location.assign(consent.consentUrl);
          })
          .exhaustive();
      })
      .tapError(error => {

        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const updateTransfer = (
    {
      beneficiary,
      details,
      schedule,
    }: {
      beneficiary: Beneficiary;
      details: Details;
      schedule: Schedule;
    },
    preparedTransferId: string,
  ) => {
    updateTransfers({
      id: preparedTransferId,
      input: {
        userId,
        accountId,
        accountMembershipId,
        initiateCreditTransfersInput: {
          consentRedirectUrl:
            window.location.origin + Router.AccountTransactionsListRoot({ accountMembershipId }),
          creditTransfers: [
            {
              amount: details.amount,
              label: details.label,
              reference: details.reference,
              ...match(schedule)
                .with({ isScheduled: true }, ({ scheduledDate, scheduledTime }) => ({
                  requestedExecutionAt: encodeDateTime(scheduledDate, `${scheduledTime}:00`),
                }))
                .otherwise(({ isInstant }) => ({
                  mode: isInstant ? "InstantWithFallback" : "Regular",
                })),
              sepaBeneficiary: {
                name: beneficiary.name,
                save: false,
                iban: beneficiary.iban,
                isMyOwnIban: false, // TODO
              },
            },
          ],
        },
      },
    })
      .mapOk(data => data.flemingUpdatePreparedCreditTransfer)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ preparedTransfer }) => {
        const params = { preparedTransferId: preparedTransfer.id, accountMembershipId };
        showToast({
          variant: "success",
          title: t("preparedTransfer.success.title"),
          description: t("preparedTransfer.success.description"),
          autoClose: false,
        });
        Router.replace("AccountPaymentsPreparedTransfers", params);
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const prepareTransfer = ({
    beneficiary,
    details,
    schedule,
  }: {
    beneficiary: Beneficiary;
    details: Details;
    schedule: Schedule;
  }) => {
    saveTransfers({
      input: {
        userId,
        accountId,
        accountMembershipId,
        initiateCreditTransfersInput: {
          consentRedirectUrl:
            window.location.origin + Router.AccountTransactionsListRoot({ accountMembershipId }),
          creditTransfers: [
            {
              amount: details.amount,
              label: details.label,
              reference: details.reference,
              ...match(schedule)
                .with({ isScheduled: true }, ({ scheduledDate, scheduledTime }) => ({
                  requestedExecutionAt: encodeDateTime(scheduledDate, `${scheduledTime}:00`),
                }))
                .otherwise(({ isInstant }) => ({
                  mode: isInstant ? "InstantWithFallback" : "Regular",
                })),
              sepaBeneficiary: {
                name: beneficiary.name,
                save: false,
                iban: beneficiary.iban,
                isMyOwnIban: false, // TODO
              },
            },
          ],
        },
      },
    })
      .mapOk(data => data.flemingPrepareCreditTransfers)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ preparedTransfer }) => {
        const params = { preparedTransferId: preparedTransfer.id, accountMembershipId };
        showToast({
          variant: "success",
          title: t("preparedTransfer.success.title"),
          description: t("preparedTransfer.success.description"),
          autoClose: false,
        });
        Router.replace("AccountPaymentsPreparedTransfers", params);
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <ResponsiveContainer style={styles.root} breakpoint={breakpoints.medium}>
      {({ large }) => (
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={[styles.headerContents, !large && styles.mobileZonePadding]}>
              {onPressClose != null && (
                <>
                  <LakeButton
                    mode="tertiary"
                    icon="dismiss-regular"
                    onPress={onPressClose}
                    ariaLabel={t("common.closeButton")}
                  />

                  <Space width={large ? 32 : 8} />
                </>
              )}

              <View style={styles.headerTitle}>
                <LakeHeading level={2} variant="h3">
                  {t("transfer.newTransfer")}
                </LakeHeading>
              </View>
            </View>
          </View>

          <Separator />

          <ScrollView contentContainerStyle={[styles.contents, large && styles.desktopContents]}>
            {match(step)
              .with({ name: "Beneficiary" }, ({ beneficiary }) => {
                return (
                  <>
                    <LakeHeading level={2} variant="h3">
                      {t("transfer.new.benefiary.title")}
                    </LakeHeading>

                    <Space height={32} />

                    <TransferWizardBeneficiary
                      canInitiatePayments={canInitiatePayments}
                      accountCountry={accountCountry}
                      accountId={accountId}
                      initialBeneficiary={beneficiary ?? repeatedBeneficiary}
                      onSave={beneficiary => setStep({ name: "Details", beneficiary })}
                    />
                  </>
                );
              })
              .with({ name: "Details" }, ({ beneficiary, details }) => {
                return (
                  <>
                    <TransferWizardBeneficiarySummary
                      isMobile={!large}
                      beneficiary={beneficiary}
                      onPressEdit={() => setStep({ name: "Beneficiary", beneficiary })}
                    />

                    <Space height={32} />

                    <LakeHeading level={2} variant="h3">
                      {t("transfer.new.details.title")}
                    </LakeHeading>

                    <Space height={32} />

                    <TransferRegularWizardDetails
                      canInitiatePayments={canInitiatePayments}
                      accountMembershipId={accountMembershipId}
                      initialDetails={details ?? repeatedDetails}
                      onPressPrevious={() => setStep({ name: "Beneficiary", beneficiary })}
                      onSave={details => setStep({ name: "Schedule", beneficiary, details })}
                    />

                    <Space height={32} />
                  </>
                );
              })
              .with({ name: "Schedule" }, ({ beneficiary, details }) => {
                return (
                  <>
                    <TransferWizardBeneficiarySummary
                      isMobile={!large}
                      beneficiary={beneficiary}
                      onPressEdit={() => setStep({ name: "Beneficiary", beneficiary })}
                    />

                    <Space height={32} />

                    <TransferRegularWizardDetailsSummary
                      isMobile={!large}
                      details={details}
                      onPressEdit={() => setStep({ name: "Details", beneficiary, details })}
                    />

                    <Space height={32} />

                    <LakeHeading level={2} variant="h3">
                      {t("transfer.new.schedule.title")}
                    </LakeHeading>

                    <Space height={32} />

                    <TransferRegularWizardSchedule
                      canInitiatePayments={canInitiatePayments}
                      beneficiary={beneficiary}
                      loading={transfer.isLoading()}
                      preparing={actualTransfer.isLoading()}
                      preparedTransferId={preparedTransferId}
                      onPressPrevious={() => setStep({ name: "Details", beneficiary, details })}
                      onSave={schedule => initiateTransfer({ beneficiary, details, schedule })}
                      onPrepare={(schedule, preparedTransferId) =>
                        match(preparedTransferId)
                          .with(P.string, id =>
                            updateTransfer({ beneficiary, details, schedule }, id),
                          )
                          .otherwise(() => prepareTransfer({ beneficiary, details, schedule }))
                      }
                    />
                  </>
                );
              })
              .otherwise(() => null)}
          </ScrollView>
        </View>
      )}
    </ResponsiveContainer>
  );
};
