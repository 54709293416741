/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useMutation, useQuery } from "@swan-io/graphql-client";
import { ExportName } from "fleming-shared-business/src/components/ExportChooser";
import { GenerateFlemingAccountStatementDocument, Statement, TransactionConnection, TransactionDetailsFragment, TransactionEdge, TransactionListPageDocument, TransactionsFiltersInput } from "../graphql/partner";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ErrorView } from "./ErrorView";
import { Connection } from "./Connection";
import { showToast } from "fleming-lake/src/state/toasts";
import { t } from "../utils/i18n";
import { getBounds, renderMT940 } from "../utils/mt940export";
import { match } from "ts-pattern";
import { renderCSV } from "../utils/csvExport";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import { translateError } from "fleming-shared-business/src/utils/i18n";
import { isNullishOrEmpty } from "fleming-lake/src/utils/nullish";

export type ExportFilterVariables = {
  accountId: string;
  after?: string,
  first: number,
  filters: TransactionsFiltersInput;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
}

type Props = {
  IBAN: string | undefined;
  exportName?: ExportName;
  variables: ExportFilterVariables;
  setExportRequested: Dispatch<SetStateAction<boolean>>;
};

/*
FIXME No time to write complex typings like setVariables IT-907
*/
const Exporter = ({
  exportData,
  setVariables,
  exportFile,
  transactions,
  exporting,
}: {
  exportData: any;
  setVariables: any;
  exportFile: any;
  transactions: any;
  exporting: any;

}) => {
  useEffect(() => {
    if ((exportData?.account?.transactions?.pageInfo.hasNextPage ?? false) && exporting) {
      setVariables(
        { after: exportData.account?.transactions?.pageInfo.endCursor }
      )
    } else if (exportData.account?.transactions?.pageInfo.hasNextPage === false && exporting) {
      exportFile(transactions as unknown as TransactionConnection)
    }
  }, [transactions]); // eslint-disable-line react-hooks/exhaustive-deps
  return <></>;
};

export const TransactionExporter = (props: Props) => {
  const {
    accountId,
    after,
    first,
    filters,
    canQueryCardOnTransaction,
    canViewAccount,
  } = props.variables;
  const [exportData, { setVariables }] = useQuery(TransactionListPageDocument, { accountId, after, first, filters, canQueryCardOnTransaction, canViewAccount});

  const [generateStatement] = useMutation(GenerateFlemingAccountStatementDocument);
  const [exporting, setExporting] = useState<boolean>(true);

  const exportFile = (transactions: TransactionConnection) => {
    setExporting(false);
    const {exportName: exportType, IBAN, setExportRequested } = props;
    // @ts-expect-error A type from graphQL schema is not supported in this code.
    const dataToExport= transactions.edges.map((edge: TransactionEdge) => edge.node as TransactionDetailsFragment);
      const { openingDate, closingDate, valid } = getBounds(dataToExport);
      if (!valid) {
        showToast({
          variant: "error",
          title: t("mt940export.dateRangeLonger.title"),
          description: t("mt940export.dateRangeLonger.description"),
        });
      } else {
        generateStatement({
          accountId,
          openingDate: isNullishOrEmpty(openingDate) ? '' : openingDate,
          closingDate: isNullishOrEmpty(closingDate) ? '' : closingDate,
          statementType: exportType === ExportName.MT940 ? "PDF" : "CSV",
        })
        .mapOk(data => data.generateAccountStatement)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk((statementData) => {
          return match(exportType)
          .with(ExportName.MT940, () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const { openingBalance, closingBalance } = statementData as Statement;

                const exportStatusesFilter = (transaction: TransactionDetailsFragment) => transaction?.statusInfo?.status !== "Pending";

                const transactionsToExport = dataToExport.filter(exportStatusesFilter);
                if (IBAN != undefined) {
                  const { isAfterUpdatedAt, isBeforeUpdatedAt } = filters;
                  const fileContent = renderMT940(
                    IBAN,
                    transactionsToExport,
                    openingBalance,
                    closingBalance,
                    (isBeforeUpdatedAt === null) ? undefined : isBeforeUpdatedAt,
                    (isAfterUpdatedAt === null) ? undefined : isAfterUpdatedAt,
                  );
                  const blob = new Blob([fileContent], { type: "text/plain" });
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.download = "mt940.sta";
                  link.href = url;
                  link.click();
                  setExportRequested(false);
                }
          })
          .with(ExportName.CSV, () => {
                if (IBAN != undefined) {
                  const fileContent = renderCSV(dataToExport);
                  const blob = new Blob([fileContent], { type: "text/plain" });
                  const url = URL.createObjectURL(blob);
                  const link = document.createElement("a");
                  link.download = "export.csv";
                  link.href = url;
                  link.click();
                  setExportRequested(false);
                }
              });
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
          setExportRequested(false);
        });
      }
    };

  return (
    <>
    {exportData.match({
      NotAsked: () => null,
      Loading: () => null,
      Done: result =>
      result.match({
        Error: error => <ErrorView error={error} />,
        Ok: exportData => (
          <Connection connection={exportData.account?.transactions}>
            {transactions => {
              return <Exporter exporting={exporting} transactions={transactions} setVariables={setVariables} exportFile={exportFile} exportData={exportData} />;
            }
            }
          </Connection>
        )
      }),
    })
    }
  </>
  );
}
