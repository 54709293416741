import { Box } from "fleming-lake/src/components/Box";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { Popover } from "fleming-lake/src/components/Popover";
import { Space } from "fleming-lake/src/components/Space";
import { colors } from "fleming-lake/src/constants/design";
import { useDisclosure } from "fleming-lake/src/hooks/useDisclosure";
import { useRef } from "react";
import { FlatList, ListRenderItemInfo, Pressable, StyleSheet, Text, View } from "react-native";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  selected: {
    color: colors.gray[500],
  },
  list: {
    paddingVertical: 20,
    marginVertical: 4,
    minWidth: 250,
    overflow: "hidden",
  },
  item: {
    display: "flex",
    paddingHorizontal: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
  },
  itemHovered: {
    backgroundColor: colors.gray[50],
  },
  availableFiltersTitle: {
    color: colors.current[500],
    paddingHorizontal: 24,
  },
});

export enum ExportName {
  "CSV" = "CSV",
  "MT940" = "MT940",
}

export function ExportChooser<ExportName extends string>({
  label,
  title,
  large = true,
  onExport,
  canExport,
}: {
  exports: Partial<Record<ExportName, unknown>>;
  label: string;
  title: string;
  large?: boolean;
  onExport: (exportName: ExportName) => void;
  available?: readonly (keyof ExportName)[];
  canExport: Record<ExportName, boolean>;
}) {
  const availableExports: { name: string; label: string }[] = [
    {
      name: ExportName.CSV,
      label: t("transactionList.export.csv"),
    },
    {
      name: ExportName.MT940,
      label: t("transactionList.export.mt940"),
    },
  ];

  const inputRef = useRef<View>(null);
  const [visible, { close, toggle }] = useDisclosure(false);
  return (
    <>
      <Box direction="row" justifyContent="start" alignItems="center">
        <LakeButton
          size="small"
          mode="secondary"
          color="gray"
          onPress={toggle}
          ref={inputRef}
          icon={"chevron-down-filled"}
          iconPosition="end"
          ariaLabel={label}
        >
          {large ? label : null}
        </LakeButton>
      </Box>

      <Popover
        role="listbox"
        matchReferenceMinWidth={true}
        onDismiss={close}
        referenceRef={inputRef}
        returnFocus={false}
        visible={visible}
      >
        <View style={styles.list}>
          <Text style={styles.availableFiltersTitle}>{title}</Text>
          <Space height={8} />

          <FlatList
            role="list"
            data={availableExports}
            keyExtractor={(_, index) => `filter-item-${index}`}
            renderItem={({ item }: ListRenderItemInfo<{ name: string; label: string }>) => {
              return (
                <Pressable
                  disabled={!canExport[item.name as ExportName]}
                  style={({ hovered }) => [styles.item, hovered && styles.itemHovered]}
                  role="button"
                  onPress={() => {
                    onExport(item.name as ExportName);
                    close();
                  }}
                >
                  <Text style={styles.selected}>{item.label}</Text>
                </Pressable>
              );
            }}
          />
        </View>
      </Popover>
    </>
  );
}
