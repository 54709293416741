import { AsyncData, Result } from "@swan-io/boxed";
import { LakeModal } from "fleming-shared-business/src/components/LakeModal";
import { translateError } from "fleming-shared-business/src/utils/i18n";
import { printIbanFormat } from "fleming-shared-business/src/utils/validation";
import { BorderedIcon } from "fleming-lake/src/components/BorderedIcon";
import { Box } from "fleming-lake/src/components/Box";
import { Fill } from "fleming-lake/src/components/Fill";
import { PlainListViewPlaceholder } from "fleming-lake/src/components/FixedListView";
import {
  EndAlignedCell,
  SimpleHeaderCell,
  SimpleRegularTextCell,
} from "fleming-lake/src/components/FixedListViewCells";
import { Icon } from "fleming-lake/src/components/Icon";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeHeading } from "fleming-lake/src/components/LakeHeading";
import { LakeLabel } from "fleming-lake/src/components/LakeLabel";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { ListRightPanel, ListRightPanelContent } from "fleming-lake/src/components/ListRightPanel";
import { ColumnConfig, PlainListView } from "fleming-lake/src/components/PlainListView";
import { Pressable } from "fleming-lake/src/components/Pressable";
import { ReadOnlyFieldList } from "fleming-lake/src/components/ReadOnlyFieldList";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { TabView } from "fleming-lake/src/components/TabView";
import { Tag } from "fleming-lake/src/components/Tag";
import { Tile } from "fleming-lake/src/components/Tile";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { breakpoints, colors, spacings } from "fleming-lake/src/constants/design";
import { useResponsive } from "fleming-lake/src/hooks/useResponsive";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { showToast } from "fleming-lake/src/state/toasts";
import { isNotNullishOrEmpty, nullishOrEmptyToUndefined } from "fleming-lake/src/utils/nullish";
import { GetNode } from "fleming-lake/src/utils/types";
import { filterRejectionsToResult } from "fleming-lake/src/utils/gql";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  FlemingCancelPreparedCreditTransferDocument,
  GetFlemingPreparedCreditTransfersForAccountDocument,
  GetFlemingPreparedCreditTransfersQuery,
  InitiateSepaCreditTransfersDocument,
  SavedCreditTransfer,
} from "../graphql/partner";
import { splitIsoDateTime } from "../utils/date";
import { formatCurrency, formatDateTime, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import {
  PreparedTransactionFiltersState,
  PreparedTransactionListFilter,
} from "./PreparedTransactionListFilter";
import { Schedule } from "./TransferRegularWizardSchedule";

const styles = StyleSheet.create({
  transactionSummary: {
    flexShrink: 1,
    flexGrow: 1,
  },
  paddedCell: {
    paddingVertical: spacings[12],
    minHeight: 72,
  },
  tile: {
    alignItems: "center",
  },
  debitAmount: {
    marginLeft: -16,
  },
  wrapText: {
    wordBreak: "break-all",
  },
  container: {
    paddingTop: 24,
    paddingHorizontal: 24,
  },
  containerDesktop: {
    paddingTop: 40,
    paddingHorizontal: 40,
  },
  filters: {
    paddingHorizontal: spacings[24],
    paddingBottom: spacings[12],
  },
  filtersLarge: {
    paddingHorizontal: spacings[40],
  },
  cancelButton: {
    alignSelf: "flex-start",
  },
  confirmButton: {
    alignSelf: "flex-start",
  },
  overflowingText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  rightPanelMobile: {
    // used only for sticky tabs
    minHeight: "100%",
  },
  rightPanelDesktop: {
    ...commonStyles.fill,
  },
  cell: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    width: 1,
  },
});

type Props = {
  accountId: string;
  accountMembershipId: string;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
  canInitiatePayments: boolean;
  userId: string;
  params: {
    search?: string | undefined;
    executed?: string;
  };
};

type Node = GetNode<
  NonNullable<GetFlemingPreparedCreditTransfersQuery>["flemingPreparedCreditTransfers"]
>;

type ExtraInfo = {
  onCancel: (id: string) => void;
  onExecute: (preparedTransfer: Node) => void;
  canInitiatePayments: boolean;
};

type PreparedTransferPanelProps = {
  large: boolean;
  preparedTransfer: Node;
  accountMembershipId: string;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
  canInitiatePayments: boolean;
  accountId: string;
  onCancel: (id: string) => void;
  onExecute: (preparedTransfer: Node) => void;
};

const PreparedTransferPanel = ({
  large,
  preparedTransfer,
  accountMembershipId,
  onCancel,
  canInitiatePayments,
  onExecute,
}: PreparedTransferPanelProps) => {
  const routes = Router.useRoute(["AccountPaymentsPreparedTransferDetailsRoot"]);

  const preparedTransferId = preparedTransfer.id;

  const tabs = useMemo(
    () => [
      {
        label: t("preparedTransfer.details.tabs.details"),
        url: Router.AccountPaymentsPreparedTransferDetailsRoot({
          accountMembershipId,
          preparedTransferId,
        }),
      },
    ],
    [preparedTransferId, accountMembershipId],
  );

  const executionDateTime = match(preparedTransfer.creditTransfer.requestedExecutionAt)
    .with(null, () => null)
    .with(undefined, () => null)
    .otherwise(requestedExecutionAt => {
      return (
        <LakeLabel
          type="viewSmall"
          label={t("transaction.executionDateTime")}
          render={() => (
            <LakeText variant="regular" color={colors.gray[900]}>
              {formatDateTime(new Date(requestedExecutionAt), "LLL")}
            </LakeText>
          )}
        />
      );
    });

  return (
    <>
      <ScrollView
        contentContainerStyle={large ? styles.rightPanelDesktop : styles.rightPanelMobile}
      >
        <Space height={24} />

        <TabView
          sticky={true}
          padding={large ? 40 : 24}
          tabs={tabs}
          otherLabel={t("common.tabs.other")}
        />

        {match(routes)
          .with({ name: "AccountPaymentsPreparedTransferDetailsRoot" }, () => (
            <ListRightPanelContent large={large} style={commonStyles.fill}>
              <ScrollView style={commonStyles.fill} contentContainerStyle={commonStyles.fill}>
                <Tile style={styles.tile}>
                  <Space height={12} />
                  <Tag color="warning">{t("transactionStatus.pending")}</Tag>
                  <Space height={12} />

                  <LakeHeading variant="h1" level={2} align="center" style={styles.debitAmount}>
                    {"-" +
                      formatCurrency(
                        Number(preparedTransfer.creditTransfer.amount.value),
                        preparedTransfer.creditTransfer.amount.currency,
                      )}
                  </LakeHeading>

                  <Space height={8} />

                  <LakeHeading
                    variant="h4"
                    level={3}
                    align="center"
                    color={colors.gray[700]}
                    style={styles.wrapText}
                  >
                    {preparedTransfer.creditTransfer.label}
                  </LakeHeading>
                </Tile>

                <Space height={24} />

                <ReadOnlyFieldList>
                  <LakeLabel
                    type="viewSmall"
                    label={t("preparedTransfer.details.label.shortExplanation")}
                    render={() => (
                      <LakeText variant="regular" color={colors.gray[900]}>
                        {preparedTransfer.creditTransfer.label ?? "-"}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="viewSmall"
                    label={t("preparedTransfer.details.label.reference")}
                    render={() => (
                      <LakeText variant="regular" color={colors.gray[900]}>
                        {preparedTransfer.creditTransfer.reference ?? "-"}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="viewSmall"
                    label={t("preparedTransfer.details.label.beneficiary")}
                    render={() => (
                      <LakeText variant="regular" color={colors.gray[900]}>
                        {preparedTransfer.creditTransfer.sepaBeneficiary?.name}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    type="viewSmall"
                    label={t("preparedTransfer.details.label.iban")}
                    render={() => (
                      <LakeText variant="regular" color={colors.gray[900]}>
                        {printIbanFormat(preparedTransfer.creditTransfer.sepaBeneficiary?.iban)}
                      </LakeText>
                    )}
                  />

                  {executionDateTime}
                </ReadOnlyFieldList>
              </ScrollView>
            </ListRightPanelContent>
          ))
          .otherwise(() => null)}
      </ScrollView>
      <>
        <Space height={24} />

        <Box
          direction="row"
          justifyContent="end"
          style={large ? styles.containerDesktop : styles.container}
        >
          <LakeButton
            size="small"
            mode="secondary"
            color="negative"
            icon="subtract-circle-regular"
            onPress={() => onCancel(preparedTransferId)}
            style={styles.cancelButton}
          >
            {t("preparedTransfer.details.cancel")}
          </LakeButton>

          <Space width={8} />

          <LakeButton
            size="small"
            mode="secondary"
            color="gray"
            icon="edit-regular"
            onPress={() =>
              Router.push("AccountPaymentsEdit", {
                accountMembershipId,
                preparedTransferId,
                type: "transfer",
                name: preparedTransfer.creditTransfer.sepaBeneficiary.name,
                IBAN: preparedTransfer.creditTransfer.sepaBeneficiary.iban,
                amount: preparedTransfer.creditTransfer.amount.value,
                currency: preparedTransfer.creditTransfer.amount.currency,
                label: nullishOrEmptyToUndefined(preparedTransfer.creditTransfer.label),
                reference: nullishOrEmptyToUndefined(preparedTransfer.creditTransfer.reference),
              })
            }
            style={styles.confirmButton}
          >
            {t("preparedTransfer.details.edit")}
          </LakeButton>

          {canInitiatePayments && (
            <>
              <Space width={8} />

              <LakeButton
                size="small"
                mode="primary"
                color="positive"
                icon="lake-transfer"
                onPress={() => onExecute(preparedTransfer)}
                style={styles.confirmButton}
              >
                {t("preparedTransfer.details.execute")}
              </LakeButton>
            </>
          )}
        </Box>
      </>
    </>
  );
};

const columns: ColumnConfig<Node, ExtraInfo>[] = [
  {
    id: "type",
    title: "",
    width: 24,
    renderTitle: () => <SimpleHeaderCell text="" />,
    renderCell: ({ item: { creditTransfer } }) =>
      match(creditTransfer.requestedExecutionAt)
        .with(P.nullish, () => (
          <View style={styles.cell}>
            <Tag icon="arrow-swap-regular" color="gray" />
          </View>
        ))
        .otherwise(() => (
          <View style={styles.cell}>
            <Tag icon="clock-regular" color="gray" />
          </View>
        )),
  },
  {
    id: "recipient",
    title: t("recurringTransfer.table.recipient"),
    width: "grow",
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { creditTransfer } }) => (
      <View style={styles.cell}>
        <Space width={16} />

        <LakeHeading variant="h5" level={3} style={styles.overflowingText}>
          {creditTransfer.sepaBeneficiary?.name}
        </LakeHeading>
        <>
          <Space width={16} />
          <Tag color="warning">{t("transactionStatus.pending")}</Tag>
        </>
      </View>
    ),
  },
  {
    id: "label",
    title: t("preparedTransfer.table.explanation"),
    width: "grow",
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { creditTransfer } }) => (
      <SimpleRegularTextCell text={creditTransfer.label ?? "-"} />
    ),
  },
  {
    id: "amount",
    title: t("preparedTransfer.table.amount"),
    width: 200,
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { creditTransfer } }) => (
      <SimpleRegularTextCell
        variant="medium"
        text={formatCurrency(Number(creditTransfer.amount.value), creditTransfer.amount.currency)}
      />
    ),
  },
  {
    id: "actions",
    title: t("preparedTransfer.table.actions"),
    width: 100,
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: ({ item, extraInfo: { onCancel, onExecute, canInitiatePayments } }) => (
      <EndAlignedCell>
        {canInitiatePayments && (
          <>
            <Pressable onPress={() => onExecute(item)}>
              {({ hovered }) => (
                <Icon
                  name="lake-transfer"
                  size={24}
                  color={hovered ? colors.positive[500] : colors.gray[500]}
                />
              )}
            </Pressable>

            <Space width={8} />
          </>
        )}

        <Pressable onPress={() => onCancel(item.id)}>
          {({ hovered }) => (
            <Icon
              name="subtract-circle-regular"
              size={16}
              color={hovered ? colors.negative[500] : colors.gray[500]}
            />
          )}
        </Pressable>

        <Space width={8} />
        <Icon name="chevron-right-filled" size={16} color={colors.gray[500]} />
      </EndAlignedCell>
    ),
  },
];

const TransactionAmount = ({ creditTransfer }: { creditTransfer: SavedCreditTransfer }) => (
  <LakeHeading level={4} variant="h5" color={colors.gray[900]}>
    {"-" + formatCurrency(Number(creditTransfer.amount.value), creditTransfer.amount.currency)}
  </LakeHeading>
);

const smallColumns: ColumnConfig<Node, ExtraInfo>[] = [
  {
    id: "type",
    title: "",
    width: 48,
    renderTitle: () => "",
    renderCell: ({ item: { creditTransfer } }) => (
      <View style={styles.cell}>
        {isNotNullishOrEmpty(creditTransfer.requestedExecutionAt) && (
          <Tag icon="clock-regular" color="gray" />
        )}

        {!isNotNullishOrEmpty(creditTransfer.requestedExecutionAt) && (
          <Tag icon="arrow-swap-regular" color="gray" />
        )}
      </View>
    ),
  },
  {
    id: "recipient",
    title: t("preparedTransfer.table.recipient"),
    width: "grow",
    renderTitle: ({ title }) => <SimpleHeaderCell text={title} />,
    renderCell: ({ item: { creditTransfer } }) => (
      <>
        <Space width={12} />

        <View style={[styles.cell, styles.paddedCell]}>
          <View style={styles.transactionSummary}>
            <LakeHeading variant="h5" level={3} style={styles.overflowingText}>
              {creditTransfer.sepaBeneficiary?.name}
            </LakeHeading>

            <TransactionAmount creditTransfer={creditTransfer} />
          </View>

          <Fill minWidth={32} />

          <View>
            <>
              <Space width={12} />
              <Tag color="warning">{t("transactionStatus.pending")}</Tag>
            </>
          </View>
        </View>
      </>
    ),
  },
  {
    id: "actions",
    title: t("preparedTransfer.table.actions"),
    width: 48,
    renderTitle: ({ title }) => <SimpleHeaderCell justifyContent="flex-end" text={title} />,
    renderCell: () => (
      <EndAlignedCell>
        <Icon name="chevron-right-filled" size={16} color={colors.gray[500]} />
      </EndAlignedCell>
    ),
  },
];

const keyExtractor = (item: Node) => item.id;

const PAGE_SIZE = 20;

export const PreparedTransferList = ({
  accountId,
  accountMembershipId,
  canQueryCardOnTransaction,
  canViewAccount,
  params,
  userId,
  canInitiatePayments,
}: Props) => {
  // use useResponsive to fit with scroll behavior set in AccountArea
  const { desktop } = useResponsive();

  const route = Router.useRoute([
    "AccountPaymentsPreparedTransfers",
    "AccountPaymentsPreparedTransferDetailsArea",
  ]);
  const [cancelPreparedTransfer, cancelResult] = useMutation(
    FlemingCancelPreparedCreditTransferDocument,
  );
  const [initiateTransfers] = useMutation(InitiateSepaCreditTransfersDocument);

  const filters: PreparedTransactionFiltersState = useMemo(() => {
    return {
      search: params.search,
    } as const;
  }, [params.search]);

  const [ data, { isLoading, reload, setVariables} ] = useQuery(
    GetFlemingPreparedCreditTransfersForAccountDocument,
    {
      userId,
      accountMembershipId,
      accountId,
      first: PAGE_SIZE,
      filters,
    },
  );

  const { endCursor, hasNextPage } = useMemo(
    () =>
      match(data)
        .with(
          AsyncData.P.Done(Result.P.Ok(P.select())),
          ({ flemingPreparedCreditTransfersForAccount }) => {
            return {
              endCursor:
                flemingPreparedCreditTransfersForAccount.preparedTransfers?.pageInfo.endCursor ??
                null,
              hasNextPage:
                flemingPreparedCreditTransfersForAccount.preparedTransfers?.pageInfo.hasNextPage ??
                false,
            };
          },
        )
        .otherwise(() => ({ endCursor: null, hasNextPage: false })),
    [data],
  );

  const onEndReached = useCallback(() => {
    if (hasNextPage && endCursor != null) {
      setVariables({
        after: endCursor,
      });
    }
  }, [hasNextPage, endCursor, setVariables]);

  const activePreparedTransferId =
    route?.name === "AccountPaymentsPreparedTransferDetailsArea"
      ? route.params.preparedTransferId
      : null;

  const closeRightPanel = () =>
    Router.push("AccountPaymentsPreparedTransfers", { accountMembershipId });

  const openPreparedTransferDetails = (preparedTransferId: string) =>
    Router.push("AccountPaymentsPreparedTransferDetailsRoot", {
      accountMembershipId,
      preparedTransferId,
    });

  const [preparedTransferToCancelId, setPreparedTransferToCancelId] = useState<string | null>(null);
  const [preparedTransferDeleted, setPreparedTransferDeleted] = useState<boolean | null>(null);

  const onCancelPreparedCreditTransfer = () => {
    if (preparedTransferToCancelId != null) {
      cancelPreparedTransfer({ id: preparedTransferToCancelId, userId, accountMembershipId })
        .mapOk(data => data.flemingCancelPreparedCreditTransfer)
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          closeRightPanel();
          setPreparedTransferToCancelId(null);
          reload();
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
        });
    }
  };

  const deletePreparedCreditTransfer = (id: string) => {
    return cancelPreparedTransfer({ id, userId, accountMembershipId })
      .mapOk(data => data.flemingCancelPreparedCreditTransfer)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        Router.replace("AccountTransactionsListRoot", { accountMembershipId });
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const scheduled = (preparedTransfer: Node): Schedule => {
    if (isNotNullishOrEmpty(preparedTransfer.creditTransfer.requestedExecutionAt)) {
      const { date, time } = splitIsoDateTime(preparedTransfer.creditTransfer.requestedExecutionAt);
      return { isScheduled: true, scheduledDate: date, scheduledTime: time };
    }
    return { isScheduled: false, isInstant: false };
  };
  type TypedObject<T> = {
    __typename?: string;
  } & T;

  function removeTypename<T>(obj: TypedObject<T>): Omit<TypedObject<T>, "__typename"> {
    const { __typename, ...cleaned } = obj;
    return cleaned;
  }

  const executeTransfer = (preparedTransfer: Node) => {
    const amount = removeTypename(preparedTransfer.creditTransfer.amount);
    const sepaBeneficiary = removeTypename(preparedTransfer.creditTransfer.sepaBeneficiary);
    initiateTransfers({
      input: {
        accountId,
        consentRedirectUrl:
          window.location.origin +
          Router.AccountPaymentsPreparedTransfers({
            accountMembershipId,
            executed: preparedTransfer.id,
          }),
        creditTransfers: [
          {
            amount,
            label: preparedTransfer.creditTransfer.label,
            reference: preparedTransfer.creditTransfer.reference,
            ...match(scheduled(preparedTransfer))
              .with({ isScheduled: true }, () => ({
                requestedExecutionAt: preparedTransfer.creditTransfer.requestedExecutionAt,
              }))
              .otherwise(({ isInstant }) => ({
                mode: isInstant ? "InstantWithFallback" : "Regular",
              })),
            sepaBeneficiary,
          },
        ],
      },
    })
      .mapOk(data => data.initiateCreditTransfers)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(({ payment }) => {
        const status = payment.statusInfo;
        const params = { paymentId: payment.id, accountMembershipId };

        return match(status)
          .with({ __typename: "PaymentInitiated" }, () => {
            Router.replace("AccountTransactionsListRoot", params);
          })
          .with({ __typename: "PaymentRejected" }, () =>
            showToast({
              variant: "error",
              title: t("transfer.consent.error.rejected.title"),
              description: t("transfer.consent.error.rejected.description"),
            }),
          )
          .with({ __typename: "PaymentConsentPending" }, ({ consent }) => {
            window.location.assign(consent.consentUrl);
          })
          .exhaustive();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  const preparedTransfers = data.mapOk(
    result =>
      result.flemingPreparedCreditTransfersForAccount.preparedTransfers?.edges.map(
        ({ node }) => node,
      ) ?? [],
  );

  const preparedTransferList = preparedTransfers
    .toOption()
    .flatMap(result => result.toOption())
    .getWithDefault([]);

  const extraInfo = useMemo<ExtraInfo>(
    () => ({
      onCancel: setPreparedTransferToCancelId,
      onExecute: executeTransfer,
      canInitiatePayments,
    }),
    [canInitiatePayments], // eslint-disable-line react-hooks/exhaustive-deps
  );
  useLayoutEffect(() => {
    if (isNotNullishOrEmpty(params.executed) && preparedTransferDeleted === null) {
      setPreparedTransferDeleted(false)
    }
  }, [params.executed, preparedTransferDeleted]);

  useLayoutEffect(() => {
    if (isNotNullishOrEmpty(params.executed) && preparedTransferDeleted === false) {
      deletePreparedCreditTransfer(params.executed);
      setPreparedTransferDeleted(true);
    }
  }); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResponsiveContainer breakpoint={breakpoints.large} style={commonStyles.fill}>
      {({ large }) => (
        <>
          <Box style={[styles.filters, large && styles.filtersLarge]}>
            <PreparedTransactionListFilter
              filters={filters}
              onChange={filters =>
                Router.push("AccountPaymentsPreparedTransfers", {
                  accountMembershipId,
                  ...filters,
                })
              }
              onRefresh={() => {reload()}}
              large={large}
            />
          </Box>

          <Space height={24} />

          {preparedTransfers.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                headerHeight={48}
                rowHeight={56}
                rowVerticalSpacing={0}
                count={PAGE_SIZE}
              />
            ),
            Done: result =>
              result.match({
                Error: () => <ErrorView />,
                Ok: preparedTransfers => (
                  <PlainListView
                    withoutScroll={!desktop}
                    keyExtractor={keyExtractor}
                    groupHeaderHeight={48}
                    headerHeight={48}
                    rowHeight={56}
                    data={preparedTransfers}
                    activeRowId={activePreparedTransferId ?? undefined}
                    extraInfo={extraInfo}
                    getRowLink={({ item }) => (
                      <Pressable onPress={() => openPreparedTransferDetails(item.id)} />
                    )}
                    columns={columns}
                    smallColumns={smallColumns}
                    onEndReached={onEndReached}
                    renderEmptyList={() => (
                      <>
                        <BorderedIcon
                          name="lake-transfer"
                          color="current"
                          size={100}
                          padding={16}
                        />

                        <Space height={24} />

                        <LakeText align="center" variant="medium" color={colors.gray[900]}>
                          {t("preparedTransfer.empty.title")}
                        </LakeText>

                        <Space height={12} />

                        <LakeText align="center" variant="smallRegular" color={colors.gray[700]}>
                          {t("preparedTransfer.empty.subtitle")}
                        </LakeText>
                      </>
                    )}
                    loading={{
                      isLoading,
                      count: PAGE_SIZE,
                    }}
                  />
                ),
              }),
          })}

          <ListRightPanel
            keyExtractor={keyExtractor}
            items={preparedTransferList}
            activeId={activePreparedTransferId}
            onActiveIdChange={openPreparedTransferDetails}
            onClose={closeRightPanel}
            closeLabel={t("common.closeButton")}
            previousLabel={t("common.previous")}
            nextLabel={t("common.next")}
            render={(item, large) => (
              <PreparedTransferPanel
                accountId={accountId}
                large={large}
                accountMembershipId={accountMembershipId}
                preparedTransfer={item}
                canQueryCardOnTransaction={canQueryCardOnTransaction}
                onCancel={setPreparedTransferToCancelId}
                onExecute={executeTransfer}
                canViewAccount={canViewAccount}
                canInitiatePayments={canInitiatePayments}
              />
            )}
          />

          <LakeModal
            visible={preparedTransferToCancelId != null}
            icon="subtract-circle-regular"
            color="negative"
            onPressClose={() => setPreparedTransferToCancelId(null)}
            title={t("preparedTransfer.confirmCancel.title")}
          >
            <LakeText>{t("preparedTransfer.confirmCancel.message")}</LakeText>
            <Space height={48} />

            <LakeButton
              color="negative"
              loading={cancelResult.isLoading()}
              onPress={onCancelPreparedCreditTransfer}
            >
              {t("preparedTransfer.confirmCancel.cta")}
            </LakeButton>
          </LakeModal>
        </>
      )}
    </ResponsiveContainer>
  );
};
