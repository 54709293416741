import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { customAlphabet } from "nanoid";
import { match } from "ts-pattern";
import { Scalars } from "../../../../server/src/graphql/partner";
import { getTransactionLabel } from "../components/TransactionListCells";
import { Amount, TransactionDetailsFragment } from "../graphql/partner";

/**
 * edge cases:
 * no filter: will list NUM_TO_EXPORT defined in the query taking opening date from the earlier transaction and closingdate from the latest transaction
 */

const DEFAULT_TRANSACTION_TYPE = "999";
const MAX_DATE_RANGE_IN_DAYS = 90;

function differenceInDays(isoDate1: string, isoDate2: string): number {
  // Parse the ISO strings into Date objects
  const date1 = new Date(isoDate1);
  const date2 = new Date(isoDate2);

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());

  // Convert milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  return Math.floor(diffInDays);
}
export const generateStatementMutation = `
mutation generateAccountStatement($accountId: ID!, $openingDate: DateTime!, $closingDate: DateTime!, $statementType: StatementType! ) {
  generateAccountStatement(
    input: { accountId: $accountId, openingDate: $openingDate, closingDate: $closingDate, statementType: $statementType }
  )
	{
    id
    closingBalance {
      value
      currency
    }
    closingDate
    openingBalance {
      currency
      value
    }
    openingDate
	}
}
`;

const CRLF = "\r\n";

export function isDifferenceGreaterThanNDays(
  startDate: string | null,
  endDate: string | null,
  days: number,
) {
  if (startDate === null || endDate === null) {
    return true;
  }
  const startDateTime = new Date(startDate).getTime();
  const endDateTime = new Date(endDate).getTime();

  const millisecondsPerDay = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  const differenceInDays = Math.abs((endDateTime - startDateTime) / millisecondsPerDay);

  return differenceInDays > days;
}

const valueDateTime = (dateTime: Scalars["DateTime"]["output"]) => {
  const date = new Date(dateTime);
  const year = date.getFullYear().toString().substring(2, 4);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

const shortDate = (dateTime: Scalars["DateTime"]["output"]) => {
  const date = new Date(dateTime);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${month}${day}`;
};

type DebitCredit = "C" | "D" | "RC" | "RD";

const addTrailingComma = (value: string) => (value.includes(",") ? value : `${value},00`);

const getAmountString = (amount: Amount) => {
  const value = addTrailingComma(amount.value.replace(".", ","));

  return `${value}`;
};
function chunkSubstr(str: string, n: number) {
  const chunks = [];
  for (let i = 0; i < str.length; i += n) {
    chunks.push(str.slice(i, i + n));
  }
  return chunks;
}

const ensureLength = (chars: string, length: number) => chars.substring(0, length - 1);

const getAccountFromIBAN = (iban: Scalars["IBAN"]["output"]) => iban.slice(-10);
const getBankIdentifierFromIBAN = (iban: Scalars["IBAN"]["output"]) => iban.slice(4, -10);

const tag20 = () => {
  const length = 16;
  const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";
  const nanoid = customAlphabet(alphabet, 13)();
  return ensureLength(`:20:FL-${nanoid}`, length);
};

const tag21 = () => {
  const length = 16;
  return ensureLength(":21:NONREF", length);
};

const tag25 = (data: {
  iban: Scalars["IBAN"]["output"];
  currency: Scalars["Currency"]["output"];
}) => {
  const length = 35;
  const bankData = `${getBankIdentifierFromIBAN(data.iban)}/${getAccountFromIBAN(data.iban)}`;
  return `:25:${ensureLength(bankData, length)}`;
};

const tag28C = () => {
  return `:28C:1`;
};

const tag60F = (
  cd: DebitCredit,
  dateTime: Scalars["DateTime"]["output"],
  currency: Scalars["Currency"]["output"],
  openingBalance: Amount,
  tag = ":60F:",
) => {
  const length = 29;
  const dateValue = valueDateTime(dateTime);
  const chars = `${tag}${cd}${dateValue}${currency}${getAmountString(openingBalance)}`;
  return ensureLength(chars, length);
};

const tag62F = (
  cd: DebitCredit,
  dateTime: Scalars["DateTime"]["output"],
  currency: Scalars["Currency"]["output"],
  openingBalance: Amount,
) => {
  return tag60F(cd, dateTime, currency, openingBalance, ":62F:");
};


export const MT940Exportable = (transaction: TransactionDetailsFragment) =>
  transaction.statusInfo.status !== "Pending";

const tag61renderer = (
  dateTime: Scalars["DateTime"]["output"],
  creditDebit: DebitCredit,
  amount: Amount,
  _transactionCode = "MSC",
  _reference: string,
) => {
  const dateValue = valueDateTime(dateTime);
  const shortDateValue = shortDate(dateTime);
  return `${dateValue}${shortDateValue}${creditDebit}${getAmountString(amount)}NONREF`;
};

const tag86renderer = (
  _dateTime: Scalars["DateTime"]["output"],
  creditDebit: DebitCredit,
  amount: Amount,
  _transactionType = DEFAULT_TRANSACTION_TYPE,
  reference: string,
  label: string,
) => {
  const DUMMY_TYPE = "999";
  const output = `:86:${DUMMY_TYPE}${label}`;
  return chunkSubstr(output, 64).join(CRLF);
};

export const isDebitOrCredit = (transaction: TransactionDetailsFragment) =>
  transaction.side === "Debit" ? "D" : "C";

const tag61 = (transaction: TransactionDetailsFragment) => {
  const length = 102;
  const record = match(transaction)
    // MSC
    .with(
      {
        __typename: "CardTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "CheckTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "FeeTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "InternalCreditTransfer",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "InternalDirectDebitTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "SEPACreditTransferTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .with(
      {
        __typename: "SEPADirectDebitTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag61renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          "MSC",
          reference,
        );
        return `:61:${record}`;
      },
    )
    .otherwise(() => `MISSING: ${transaction.__typename}, ${transaction.type}`);
  return ensureLength(record, length);
};

const tag86 = (transaction: TransactionDetailsFragment) => {
  const length = 390;
  const label = getTransactionLabel(transaction);
  const record = match(transaction)
    // MSC
    .with(
      {
        __typename: "CardTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "CheckTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "FeeTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "InternalCreditTransfer",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "InternalDirectDebitTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "SEPACreditTransferTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .with(
      {
        __typename: "SEPADirectDebitTransaction",
      },
      transaction => {
        const { executionDate, amount, reference } = transaction;
        const record = tag86renderer(
          executionDate,
          isDebitOrCredit(transaction),
          amount,
          DEFAULT_TRANSACTION_TYPE,
          reference,
          label,
        );
        return `${record}`;
      },
    )
    .otherwise(() => ``);

  return ensureLength(record, length);
};

const MT940Line = (prev: string, add: string): string => {
  const next = prev;
  return next + add + CRLF;
};

type Bounds = {
  openingDate: Scalars["DateTime"]["output"] | null;
  closingDate: Scalars["DateTime"]["output"] | null;
  valid: boolean;
};

function addOneDay(isoDate: string): string {
  // Parse the ISO string into a Date object
  const date = new Date(isoDate);

  // Add one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

  // Convert the date back to an ISO string
  return date.toISOString();
}

export const getBounds = (transactions: TransactionDetailsFragment[]) => {
  const initialBounds: Bounds = {
    openingDate: null,
    closingDate: null,
    valid: false,
  };

  if (transactions.length === 1 && isNotNullish(transactions[0])) {
    return {
      openingDate: transactions[0].executionDate,
      closingDate: addOneDay(transactions[0].executionDate),
      valid: true,
    };
  }

  const bounds: Bounds = transactions.reduce((current, transaction: TransactionDetailsFragment) => {
    if (current.openingDate === null) {
      current.openingDate = transaction.executionDate;
    } else if (current.openingDate > transaction.executionDate) {
      current.openingDate = transaction.executionDate;
    }
    if (current.closingDate === null) {
      current.closingDate = transaction.executionDate;
    } else if (current.closingDate < transaction.executionDate) {
      current.closingDate = transaction.executionDate;
    }
    return current;
  }, initialBounds);
  const valid =
    bounds.openingDate !== null && bounds.closingDate !== null
      ? differenceInDays(bounds.openingDate, bounds.closingDate) <= MAX_DATE_RANGE_IN_DAYS
      : false;
  return {
    ...bounds,
    valid,
  };
};

export const renderMT940 = (
  iban: Scalars["IBAN"]["output"],
  transactions: TransactionDetailsFragment[],
  openingBalance: Amount,
  closingBalance: Amount,
  isBeforeUpdatedAt?: string,
  isAfterUpdatedAt?: string,
) => {
  const { openingDate, closingDate } = getBounds(transactions);

  if (openingDate === null || closingDate === null) {
    return "";
  }
  const useOpeningDate = isAfterUpdatedAt ?? openingDate;
  const useCloingDate = isBeforeUpdatedAt ?? closingDate;
  const currency = "EUR";
  let result = MT940Line("", tag20());
  result = MT940Line(result, tag21());
  result = MT940Line(result, tag25({ iban, currency }));
  result = MT940Line(result, tag28C());
  result = MT940Line(result, tag60F("C", useOpeningDate, currency, openingBalance));
  const list = transactions
    .map((transaction: TransactionDetailsFragment) => {
      const tag61entry = tag61(transaction);
      /**
       * if we don't have a render type we return '' and it should be ignored for the export
       */
      return tag61entry !== "" ? tag61entry + CRLF + tag86(transaction) : null;
    })
    .filter(entry => entry !== null);
  const footer = tag62F("C", useCloingDate, currency, closingBalance);
  return result + list.join(CRLF) + CRLF + footer;
};
