import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "fleming-lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "fleming-lake/src/components/FixedListView";
import { FocusTrapRef } from "fleming-lake/src/components/FocusTrap";
import { ListRightPanel } from "fleming-lake/src/components/ListRightPanel";
import { Pressable } from "fleming-lake/src/components/Pressable";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { breakpoints, spacings } from "fleming-lake/src/constants/design";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { useCallback, useMemo, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import {
  CardPageQuery,
  CardTransactionsPageDocument,
  IdentificationFragment,
} from "../graphql/partner";
import { getMemberName } from "../utils/accountMembership";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { CardItemIdentityVerificationGate } from "./CardItemIdentityVerificationGate";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TransactionDetail } from "./TransactionDetail";
import { TransactionList } from "./TransactionList";
import { TransactionFiltersState, TransactionListFilter } from "./TransactionListFilter";

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  filters: {
    paddingVertical: spacings[12],
    paddingHorizontal: spacings[24],
  },
  filtersLarge: {
    paddingHorizontal: spacings[40],
  },
});

const NUM_TO_RENDER = 20;

type Props = {
  card: Card;
  projectId: string;
  accountMembershipId: string;
  userId: string;
  accountId: string | undefined;
  cardId: string;

  isCurrentUserCardOwner: boolean;
  cardRequiresIdentityVerification: boolean;
  onRefreshAccountRequest: () => void;
  lastRelevantIdentification: Option<IdentificationFragment>;

  params: {
    isAfterUpdatedAt?: string | undefined;
    isBeforeUpdatedAt?: string | undefined;
    search?: string | undefined;
    status?: string[] | undefined;
  };

  canViewAccount: boolean;
  transactionUpdating: boolean;
  setTransactionUpdating: (state: boolean) => void;
};

const availableFilters = [
  "isAfterUpdatedAt",
  "isBeforeUpdatedAt",
  "isBeforeUpdatedAt",
  "search",
  "status",
] as const;

const DEFAULT_STATUSES = [
  "Booked" as const,
  "Canceled" as const,
  "Pending" as const,
  "Rejected" as const,
];

type Card = NonNullable<CardPageQuery["card"]>;

export const CardItemTransactionList = ({
  card: cardFromProps,
  cardId,
  projectId,
  accountMembershipId,
  params,
  isCurrentUserCardOwner,
  cardRequiresIdentityVerification,
  onRefreshAccountRequest,
  lastRelevantIdentification,
  canViewAccount,
  userId,
  accountId,
  setTransactionUpdating,
  transactionUpdating,
}: Props) => {
  const filters: TransactionFiltersState = useMemo(() => {
    return {
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      search: params.search,
      paymentProduct: undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with("Booked", "Canceled", "Pending", "Rejected", "Released", "Upcoming", item =>
                Option.Some(item),
              )
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params.isAfterUpdatedAt, params.isBeforeUpdatedAt, params.search, params.status]);

  const hasFilters = Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(CardTransactionsPageDocument, {
    cardId,
    first: NUM_TO_RENDER,
    filters: {
      ...filters,
      paymentProduct: undefined,
      status: filters.status ?? DEFAULT_STATUSES,
    },
    canQueryCardOnTransaction: true,
    canViewAccount,
  });

  const [activeTransactionId, setActiveTransactionId] = useState<string | null>(null);

  const transactions = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(data => Option.fromNullable(data.card?.transactions))
    .map(({ edges }) => edges.map(({ node }) => node))
    .getWithDefault([]);

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback(
    (element: HTMLElement) => panelRef.current?.setInitiallyFocusedElement(element),
    [],
  );

  return (
    <ResponsiveContainer style={styles.root} breakpoint={breakpoints.large}>
      {({ large }) => (
        <>
          {match({ hasFilters, data })
            .with(
              {
                hasFilters: false,
                data: AsyncData.P.Done(Result.P.Ok({ card: { transactions: { totalCount: 0 } } })),
              },
              () => null,
            )
            .otherwise(() => (
              <Box style={[styles.filters, large && styles.filtersLarge]}>
                <TransactionListFilter
                  filters={filters}
                  onChange={filters =>
                    Router.push("AccountCardsItemTransactions", {
                      accountMembershipId,
                      cardId,
                      ...filters,
                    })
                  }
                  available={availableFilters}
                  onRefresh={() => {
                    reload();
                  }}
                  large={large}
                />
              </Box>
            ))}

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                headerHeight={48}
                groupHeaderHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: ({ card }) => (
                  <Connection connection={card?.transactions}>
                    {transactions => (
                      <TransactionList
                        withStickyTabs={true}
                        transactions={transactions?.edges ?? []}
                        getRowLink={({ item }) => (
                          <Pressable onPress={() => setActiveTransactionId(item.id)} />
                        )}
                        pageSize={NUM_TO_RENDER}
                        activeRowId={activeTransactionId ?? undefined}
                        onActiveRowChange={onActiveRowChange}
                        loading={{
                          isLoading,
                          count: 2,
                        }}
                        onEndReached={() => {
                          if (transactions?.pageInfo.hasNextPage ?? false) {
                            setVariables({
                              after: transactions?.pageInfo.endCursor ?? undefined,
                            });
                          }
                        }}
                        renderEmptyList={() =>
                          hasFilters ? (
                            <FixedListViewEmpty
                              icon="lake-transfer"
                              borderedIcon={true}
                              title={t("common.list.noResults")}
                              subtitle={t("common.list.noResultsSuggestion")}
                            />
                          ) : (
                            <FixedListViewEmpty
                              icon="lake-transfer"
                              borderedIcon={true}
                              title={t("transansactionList.noResults")}
                            >
                              {cardRequiresIdentityVerification ? (
                                <>
                                  <Space height={24} />

                                  <CardItemIdentityVerificationGate
                                    recommendedIdentificationLevel={
                                      card?.accountMembership.recommendedIdentificationLevel ??
                                      "QES"
                                    }
                                    isCurrentUserCardOwner={isCurrentUserCardOwner}
                                    projectId={projectId}
                                    description={t("card.identityVerification.transactions")}
                                    descriptionForOtherMember={t(
                                      "card.identityVerification.transactions.otherMember",
                                      {
                                        name: getMemberName({
                                          accountMembership: cardFromProps.accountMembership,
                                        }),
                                      },
                                    )}
                                    onComplete={onRefreshAccountRequest}
                                    lastRelevantIdentification={lastRelevantIdentification}
                                  />
                                </>
                              ) : null}
                            </FixedListViewEmpty>
                          )
                        }
                      />
                    )}
                  </Connection>
                ),
              }),
          })}

          <ListRightPanel
            ref={panelRef}
            keyExtractor={item => item.id}
            activeId={activeTransactionId}
            onActiveIdChange={setActiveTransactionId}
            onClose={() => setActiveTransactionId(null)}
            items={transactions}
            render={(transaction, large) => (
              <TransactionDetail
                transactionId={transaction.id}
                accountId={accountId}
                userId={userId}
                setTransactionUpdating={setTransactionUpdating}
                transactionUpdating={transactionUpdating}
                large={large}
                transaction={transaction}
                accountMembershipId={accountMembershipId}
                canQueryCardOnTransaction={true}
                canViewAccount={canViewAccount}
              />
            )}
            closeLabel={t("common.closeButton")}
            previousLabel={t("common.previous")}
            nextLabel={t("common.next")}
          />
        </>
      )}
    </ResponsiveContainer>
  );
};
