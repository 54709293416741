import { Validator } from "@swan-io/use-form";
import dayjs from "dayjs";
import { noop } from "fleming-lake/src/utils/function";
import { isValid as isValidIban } from "iban";
import { match } from "ts-pattern";
import { locale, t } from "./i18n";
import { AccountCountry } from "./templateTranslations";

const EMAIL_REGEX = /^[A-Z0-9_+.-]*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i;
const VAT_NUMBER_REGEX =
  /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;

// (AT)?U[0-9]{8} |                              # Austria
// (BE)?0[0-9]{9} |                              # Belgium
// (BG)?[0-9]{9,10} |                            # Bulgaria
// (CY)?[0-9]{8}L |                              # Cyprus
// (CZ)?[0-9]{8,10} |                            # Czech Republic
// (DE)?[0-9]{9} |                               # Germany
// (DK)?[0-9]{8} |                               # Denmark
// (EE)?[0-9]{9} |                               # Estonia
// (EL|GR)?[0-9]{9} |                            # Greece
// (ES)?[0-9A-Z][0-9]{7}[0-9A-Z] |               # Spain
// (FI)?[0-9]{8} |                               # Finland
// (FR)?[0-9A-Z]{2}[0-9]{9} |                    # France
// (GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3}) | # United Kingdom
// (HU)?[0-9]{8} |                               # Hungary
// (IE)?[0-9]S[0-9]{5}L |                        # Ireland
// (IT)?[0-9]{11} |                              # Italy
// (LT)?([0-9]{9}|[0-9]{12}) |                   # Lithuania
// (LU)?[0-9]{8} |                               # Luxembourg
// (LV)?[0-9]{11} |                              # Latvia
// (MT)?[0-9]{8} |                               # Malta
// (NL)?[0-9]{9}B[0-9]{2} |                      # Netherlands
// (PL)?[0-9]{10} |                              # Poland
// (PT)?[0-9]{9} |                               # Portugal
// (RO)?[0-9]{2,10} |                            # Romania
// (SE)?[0-9]{12} |                              # Sweden
// (SI)?[0-9]{8} |                               # Slovenia
// (SK)?[0-9]{10}                                # Slovakia

export const isValidVatNumber = (maybeVat: string) => {
  return VAT_NUMBER_REGEX.test(maybeVat);
};

export const isValidEmail = (maybeEmail: string) => {
  return EMAIL_REGEX.test(maybeEmail);
};

export const validateNullableRequired: Validator<string | undefined> = value => {
  if (value == null || !value) {
    return t("error.requiredField");
  }
};

export const validateRequired: Validator<string> = value => {
  if (!value) {
    return t("error.requiredField");
  }
};

export const validateBooleanRequired: Validator<boolean | undefined> = value => {
  if (value == null || !value) {
    return t("error.requiredField");
  }
};

export const validateIndividualTaxIdentificationNumber =
  (accountCountry: AccountCountry): Validator<string | undefined> =>
  value => {
    if (value == null || !value) {
      return;
    }

    return match(accountCountry)
      .with("DEU", () => {
        // accept 11 digits
        if (!/^\d{11}$/.test(value)) {
          return t("common.form.invalidTaxIdentificationNumber");
        }
      })
      .with("ESP", () => {
        // accept 9 characters
        if (!/^[a-zA-Z0-9]{9}$/.test(value)) {
          return t("common.form.invalidTaxIdentificationNumber");
        }
      })
      .otherwise(noop);
  };

export const validateCompanyTaxNumber =
  (accountCountry: AccountCountry): Validator<string | undefined> =>
  value => {
    if (value == null || !value) {
      return;
    }

    return match(accountCountry)
      .with("DEU", () => {
        // accept 10 or 11 digits
        if (!/^\d{10,11}$/.test(value)) {
          return t("common.form.invalidTaxNumber");
        }
      })
      .with("ESP", () => {
        // accept 9 characters
        if (!/^[a-zA-Z0-9]{9}$/.test(value)) {
          return t("common.form.invalidTaxIdentificationNumber");
        }
      })
      .otherwise(noop);
  };

export { printFormat as printIbanFormat } from "iban";

export const validateIban = (iban: string) => {
  if (!isValidIban(iban)) {
    return t("error.iban.invalid");
  }
};

export const validateName: Validator<string> = value => {
  if (!value) {
    return;
  }

  // Rule copied from the backend
  if (value.length > 100) {
    return t("common.form.invalidName");
  }

  // This regex was copied from the backend to ensure that the validation is the same
  // Matches all unicode letters, spaces, dashes, apostrophes, commas, and single quotes
  const isValid = value.match(
    /^(?:[A-Za-zÀ-ÖÙ-öù-ƿǄ-ʯʹ-ʽΈ-ΊΎ-ΡΣ-ҁҊ-Ֆա-ևႠ-Ⴥა-ჺᄀ-፜፩-ᎏᵫ-ᶚḀ-῾ⴀ-ⴥ⺀-⿕ぁ-ゖゝ-ㇿ㋿-鿯鿿-ꒌꙀ-ꙮꚀ-ꚙꜦ-ꞇꞍ-ꞿꥠ-ꥼＡ-Ｚａ-ｚ]| |'|-|Ά|Ό|,)*$/,
  );

  if (!isValid) {
    return t("common.form.invalidName");
  }
};

// birthdate can be only in the past, today or tomorrow (to allow timezones) - and after 1900
export const validateBirthdate: Validator<string> = value => {
  const date = dayjs(value, locale.dateFormat);
  if (!date.isValid() || date.year() < 1900) {
    return t("common.form.invalidDate");
  }

  const tomorrow = dayjs().startOf("day").add(1, "day");

  if (date.isAfter(tomorrow)) {
    return t("common.form.birthdateCannotBeFuture");
  }
};

export const validatePostCode: Validator<string> = value => {
  if (!value) {
    return;
  }

  if (!value.match("^[0-9]{5}$")) {
    return t("common.form.invalidPostCode");
  }
};

export const validateNullablePercentage: Validator<string | undefined> = value => {
  if (value == null || !value) {
    return;
  }

  const number = parseFloat(value);
  if (isNaN(number) || number < 0 || number > 100) {
    return t("common.form.invalidPercentage");
  }
};
