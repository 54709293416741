import { match } from "ts-pattern";
import { Amount, TransactionDetailsFragment, TransactionSide } from "../graphql/partner";
import { getTransactionLabel } from "../components/TransactionListCells";
import { t } from "./i18n";

const CRLF = "\r\n";

const columnNames = () => `Datum;Typ;Beschreibung;Status;Guthaben EUR;Lastschrift EUR;;;;;;` + CRLF;

const getStatusText = (transaction: TransactionDetailsFragment) => 
  match(transaction.statusInfo.__typename)
  .with("PendingTransactionStatusInfo", () => t("transactionStatus.pending")
  )
  .with("RejectedTransactionStatusInfo", () => 
    t("transactionStatus.rejected")
  )
  .with("CanceledTransactionStatusInfo", () => 
    t("transactionStatus.canceled")
  )
  .otherwise(() => t("transactionStatus.booked"))

const line = (executionDate: string, type: string, transactionSide: TransactionSide, amount: Amount, label: string, status: string) =>
{
  const onlyDate = new Date(executionDate);
  const csvDate = onlyDate.toLocaleDateString('de');
  const credit = (transactionSide === 'Credit') ? amount.value.replace('.',',') : '';
  const debit = (transactionSide === 'Debit') ? amount.value.replace('.',',') : '';
  const csvLine = `${csvDate};${type};${label};${status};${credit};${debit};;;;;;`;
  return csvLine;
}

const renderTransaction = (transaction: TransactionDetailsFragment) => {
  const label = getTransactionLabel(transaction);
  const status = getStatusText(transaction);

  const record = match(transaction)
	.with({
		__typename: "CardTransaction",
	}, (transaction) => {
		const { executionDate, amount, side } = transaction;
		const record = line(executionDate, t("transactions.method.Card"), side, amount, label, status);
		return record;
	}).with({
		__typename: "CheckTransaction",
	}, (transaction) => {
		const { executionDate, amount, side } = transaction;
		const record = line(executionDate, t("transactions.method.Check"), side, amount, label, status);
		return record;
	}).with({
		__typename: "FeeTransaction",
	}, (transaction) => {
		const { executionDate, amount, side } = transaction;
		const record = line(executionDate, t("transactions.method.Fees"), side, amount, label, status);
		return record;
	}).with({
	__typename: "InternalCreditTransfer",
	}, (transaction) => {
		const { executionDate, amount, side } = transaction;
		const record = line(executionDate, t("transactions.method.Transfer"), side, amount, label, status);
		return record;
	}).with({
		__typename: "InternalDirectDebitTransaction",
		}, (transaction) => {
      const { executionDate, amount, side } = transaction;
      const record = line(executionDate, t("transactions.method.Transfer"), side, amount, label, status);
      return record;
    }).with({
		__typename: "SEPACreditTransferTransaction",
		}, (transaction) => {
      const { executionDate, amount, side } = transaction;
      const record = line(executionDate, t("transactions.method.Transfer"), side, amount, label, status);
      return record;
    }).with({
		__typename: "SEPADirectDebitTransaction",
		}, (transaction) => {
      const { executionDate, amount, side } = transaction;
      const record = line(executionDate, t("transactions.method.DirectDebit"), side, amount, label, status);
      return record;
    })
    .otherwise(() => ``)

  return record;
}

const renderTransactions = (transactions: TransactionDetailsFragment[]) => {
  const list = transactions.map((transaction: TransactionDetailsFragment) => {
		return renderTransaction(transaction);
	}).filter((entry) => entry !== '').join(CRLF) + CRLF;
  return list;
}

export const renderCSV = (transactions: TransactionDetailsFragment[]) => {
  return columnNames() + renderTransactions(transactions);
}
