import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { BorderedIcon } from "fleming-lake/src/components/BorderedIcon";
import { Box } from "fleming-lake/src/components/Box";
import { Fill } from "fleming-lake/src/components/Fill";
import { LakeAlert } from "fleming-lake/src/components/LakeAlert";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { Link } from "fleming-lake/src/components/Link";
import { LoadingView } from "fleming-lake/src/components/LoadingView";
import { Separator } from "fleming-lake/src/components/Separator";
import { Space } from "fleming-lake/src/components/Space";
import { SwanLogo } from "fleming-lake/src/components/SwanLogo";
import { Tile } from "fleming-lake/src/components/Tile";
import { WithPartnerAccentColor } from "fleming-lake/src/components/WithPartnerAccentColor";
import {
  backgroundColor,
  breakpoints,
  colors,
  invariantColors,
  spacings,
} from "fleming-lake/src/constants/design";
import { useResponsive } from "fleming-lake/src/hooks/useResponsive";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { isMobile } from "fleming-lake/src/utils/userAgent";
import { useCallback } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { ProjectLoginPageDocument } from "../graphql/unauthenticated";
import { openPopup } from "../states/popup";
import { env } from "../utils/env";
import { getFirstSupportedLanguage, t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    marginHorizontal: "auto",
    padding: spacings[24],
  },
  clientLogo: {
    height: 25,
    width: "100%",
  },
  swanLogo: {
    height: 20,
    paddingVertical: 5,
    width: "100%",
  },
  tile: {
    paddingHorizontal: spacings[72],
    paddingVertical: spacings[72],
  },
  iconContainer: {
    margin: "auto",
  },
  link: {
    wordBreak: "keep-all",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

const HelpLink = ({ to, children }: { to: string; children: string }) => (
  <LakeText variant="smallRegular" style={styles.link}>
    <Link to={to} style={({ hovered }) => hovered && styles.underline} target="blank">
      {children}
    </Link>
  </LakeText>
);
const DemoLink = ({ to = "/demologin", children }: { to?: string; children: string }) => (
  <LakeText variant="smallRegular" style={styles.link} align="center">
    <Link to={to} style={({ hovered }) => hovered && styles.underline} target="blank">
      {children}
    </Link>
  </LakeText>
);

// We keep swan logic for the future and avoid conflicts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SUPPORT_ROOT_URL = `https://support.swan.io/hc/${getFirstSupportedLanguage([
  "en",
  "fr",
  "de",
  "es",
  "it",
])}`;

const FLEMING_SUPPORT_ROOT_URL = `https://support.swan.io/hc/de`;

type LoginContentProps = {
  accentColor: string;
  onLogin: () => void;
  onSignup?: () => void;
  onDemo?: () => void;
};

const LoginContent = ({ accentColor, onLogin, onSignup }: LoginContentProps) => {
  return (
    <WithPartnerAccentColor color={accentColor}>
      <View style={styles.iconContainer}>
        <BorderedIcon name="lake-building-bank" size={100} padding={8} color="partner" />
      </View>

      <Space height={32} />

      <LakeButton color="partner" onPress={onLogin}>
        {t("login.buttonText")}
      </LakeButton>

      <Space height={32} />

      <LakeButton color="gray" mode="secondary" onPress={onSignup}>
        {t("signup.buttonText")}
      </LakeButton>

      <Separator space={16} />

      <LakeText color={colors.gray[900]} variant="semibold">
        {t("login.needHelp")}
      </LakeText>

      <Space height={8} />
      <HelpLink to={`${FLEMING_SUPPORT_ROOT_URL}-150`}>{t("login.linkHow")}</HelpLink>
      <Space height={8} />

      <HelpLink
        to={`${FLEMING_SUPPORT_ROOT_URL}-150/articles/5490446960797-How-do-I-report-fraud-`}
      >
        {t("login.linkFraud")}
      </HelpLink>
    </WithPartnerAccentColor>
  );
};

export const ProjectLoginPage = ({
  projectId,
  message,
}: {
  projectId: string;
  sessionExpired?: boolean;
  message?: string;
}) => {
  const { desktop } = useResponsive(breakpoints.medium);
  const envType = env.APP_TYPE === "LIVE" ? "Live" : "Sandbox";
  const [projectInfos] = useQuery(ProjectLoginPageDocument, { projectId, env: envType });

  const handleButtonPress = useCallback(() => {
    const redirectTo = Router.ProjectRootRedirect();
    const params = new URLSearchParams();
    params.set("projectId", projectId);

    if (isMobile) {
      params.set("redirectTo", redirectTo);
      window.location.replace(`/auth/login?${params.toString()}`);
    } else {
      params.set("redirectTo", Router.PopupCallback());
      openPopup({
        url: `/auth/login?${params.toString()}`,
        onClose: () => {
          // We use location.replace to be sure that the auth
          // cookie is correctly written before changing page
          // (history pushState does not seem to offer these guarantees)
          window.location.replace(redirectTo);
        },
      });
    }
  }, [projectId]);

  const handleSignupPress = useCallback(() => {
    window.location.replace(`${env.ONBOARDING_URL}/onboarding/company/start`);
  }, []);

  // const handleDemoPress = useCallback(() => {
  //   const redirectTo = Router.ProjectRootRedirect();
  //   const params = new URLSearchParams();
  //   params.set("projectId", projectId);
  //   params.set("redirectTo", redirectTo);
  //   window.location.replace(`/demologin`);
  // }, [projectId]);

  return match(
    projectInfos.mapOk(({ projectInfoById }) => ({
      accentColor: projectInfoById.accentColor ?? invariantColors.gray,
      name: projectInfoById.name,
      logoUri: projectInfoById.logoUri ?? undefined,
    })),
  )
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ accentColor, name, logoUri }) => {
      return (
        <ScrollView style={styles.base} contentContainerStyle={styles.content}>
          <Box role="banner" alignItems="center">
            {isNotNullish(logoUri) ? (
              <Image
                source={{ uri: logoUri }}
                resizeMode="contain"
                aria-label={name}
                style={styles.clientLogo}
              />
            ) : (
              <SwanLogo style={styles.swanLogo} />
            )}
          </Box>

          <Fill minHeight={48} />

          {/*{sessionExpired && (*/}
          {/*  <>*/}
          {/*    <Space height={24} />*/}
          {/*    <LakeAlert variant="warning" title={t("login.sessionExpired.title")} />*/}
          {/*    <Space height={desktop ? 24 : 48} />*/}
          {/*  </>*/}
          {/*)}*/}

          {message != null && !message && (
            <>
              <Space height={24} />
              <LakeAlert variant="warning" title={t("login.demoPhoneNotValid.title")} />
              <Space height={desktop ? 24 : 48} />
            </>
          )}

          {desktop ? (
            <Tile style={styles.tile}>
              <LoginContent
                accentColor={accentColor}
                onLogin={handleButtonPress}
                onSignup={handleSignupPress}
              />
            </Tile>
          ) : (
            <View>
              <LoginContent
                accentColor={accentColor}
                onLogin={handleButtonPress}
                onSignup={handleSignupPress}
              />
            </View>
          )}

          <Fill minHeight={24} />
          <Space height={12} />

          <LakeText align="center" variant="smallRegular" color={colors.gray[700]}>
            {t("login.disclaimer.swan")}
          </LakeText>

          <Space height={8} />
          <DemoLink>{t("login.demo.link")}</DemoLink>
        </ScrollView>
      );
    })
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .otherwise(() => <LoadingView color={colors.gray[400]} style={styles.base} />);
};
