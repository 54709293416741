import { Box } from "fleming-lake/src/components/Box";
import { Fill } from "fleming-lake/src/components/Fill";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeSearchField } from "fleming-lake/src/components/LakeSearchField";
import { Space } from "fleming-lake/src/components/Space";
import { ReactNode } from "react";
import { t } from "../utils/i18n";

export type PreparedTransactionFiltersState = {
  search: string | undefined;
};

type PreparedTransactionListFilterProps = {
  filters: PreparedTransactionFiltersState;
  onChange: (values: Partial<PreparedTransactionFiltersState>) => void;
  onRefresh: () => void;
  available?: readonly (keyof PreparedTransactionFiltersState)[];
  children?: ReactNode;
  large?: boolean;
};

export const PreparedTransactionListFilter = ({
  filters,
  children,
  onChange,
  onRefresh,
  large = true,
}: PreparedTransactionListFilterProps) => {
  return (
    <>
      <Box direction="row" alignItems="center">
        {children != null ? (
          <>
            {children}

            <Space width={16} />
          </>
        ) : null}

        {large ? (
          <>
            <Space width={16} />

            <LakeButton
              ariaLabel={t("common.refresh")}
              mode="secondary"
              size="small"
              icon="arrow-counterclockwise-filled"
              onPress={onRefresh}
            />
          </>
        ) : null}

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={filters.search ?? ""}
          onChangeText={search => onChange({ search })}
        />
      </Box>

      <Space height={12} />
    </>
  );
};
