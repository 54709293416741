//TODO: fix linting in IT-680 20240301
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Array, Option } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { isNotNullish } from "fleming-lake/src/utils/nullish";
import { Box } from "fleming-lake/src/components/Box";
import {
  FixedListViewEmpty,
  PlainListViewPlaceholder,
} from "fleming-lake/src/components/FixedListView";
import { FocusTrapRef } from "fleming-lake/src/components/FocusTrap";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { ListRightPanel } from "fleming-lake/src/components/ListRightPanel";
import { Pressable } from "fleming-lake/src/components/Pressable";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { RightPanel } from "fleming-lake/src/components/RightPanel";
import { Space } from "fleming-lake/src/components/Space";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { breakpoints, spacings } from "fleming-lake/src/constants/design";
import { ExportChooser, ExportName } from "fleming-shared-business/src/components/ExportChooser";
import { useCallback, useMemo, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TransactionDetail } from "../components/TransactionDetail";
import { TransactionList } from "../components/TransactionList";
import {
  TransactionFiltersState,
  TransactionListFilter,
} from "../components/TransactionListFilter";
import {
  PaymentProduct,
  FlemingTransactionListPageDocument,
} from "../graphql/partner";
import { useTransferToastWithRedirect } from "../hooks/useTransferToastWithRedirect";

import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { TransactionExporter } from "../components/TransactionExporter";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
  filters: {
    paddingHorizontal: spacings[24],
    paddingBottom: spacings[12],
  },
  filtersLarge: {
    paddingHorizontal: spacings[40],
  },
  button: {
    paddingHorizontal: spacings[24],
    paddingVertical: spacings[12],
  },
  buttonLarge: {
    paddingHorizontal: spacings[40],
    paddingVertical: spacings[12],
  },
});

const NUM_TO_RENDER = 20;
const NUM_TO_EXPORT = 20;

type Props = {
  userId: string;
  accountId: string;
  accountMembershipId: string;
  canQueryCardOnTransaction: boolean;
  accountStatementsVisible: boolean;
  canViewAccount: boolean;
  transferConsent: Option<{ status: string; isStandingOrder: boolean }>;
  params: {
    isAfterUpdatedAt?: string | undefined;
    isBeforeUpdatedAt?: string | undefined;
    paymentProduct?: string[] | undefined;
    search?: string | undefined;
    transactionStatus?: string[] | undefined;
    statements?: string | undefined;
  };
};

const DEFAULT_STATUSES = [
  "Booked" as const,
  "Canceled" as const,
  "Pending" as const,
  "Rejected" as const,
];

const EXPORT_STATUSES = [
  "Booked" as const,
  "Released" as const,
]

export const TransactionListPage = ({
  userId,
  accountId,
  accountMembershipId,
  transferConsent,
  params,
  canQueryCardOnTransaction,
  accountStatementsVisible,
  canViewAccount,
}: Props) => {
  useTransferToastWithRedirect(transferConsent, () =>
    Router.replace("AccountTransactionsListRoot", { accountMembershipId }),
  );
  const route = Router.useRoute(["AccountTransactionsListDetail"]);

  const filters: TransactionFiltersState = useMemo(() => {
    return {
      includeRejectedWithFallback: false,
      isAfterUpdatedAt: params.isAfterUpdatedAt,
      isBeforeUpdatedAt: params.isBeforeUpdatedAt,
      paymentProduct: isNotNullish(params.paymentProduct)
        ? Array.filterMap(params.paymentProduct, item =>
            match(item)
              .with("CreditTransfer", "DirectDebit", "Card", "Fees", "Check", value =>
                Option.Some(value),
              )
              .otherwise(() => Option.None()),
          )
        : undefined,
      search: params.search,
      status: isNotNullish(params.transactionStatus)
        ? Array.filterMap(params.transactionStatus, item =>
            match(item)
              .with("Booked", "Canceled", "Pending", "Rejected", "Released", item =>
                Option.Some(item),
              )
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [
    params.isAfterUpdatedAt,
    params.isBeforeUpdatedAt,
    params.paymentProduct,
    params.search,
    params.transactionStatus,
  ]);

  const hasFilters = Object.values(filters).some(isNotNullish);

  const paymentProduct = useMemo(() => {
    const actualPaymentProduct: PaymentProduct[] = [];
    filters.paymentProduct?.forEach(item => {
      const items = match(item)
        .returnType<PaymentProduct[]>()
        .with("Card", "Fees", "Check", value => [value])
        .with("CreditTransfer", () => ["SEPACreditTransfer", "InternalCreditTransfer"])
        .with("DirectDebit", () => ["SEPADirectDebit", "InternalDirectDebit"])
        .exhaustive();
      actualPaymentProduct.push(...items);
    });
    return actualPaymentProduct.length > 0 ? actualPaymentProduct : undefined;
  }, [filters]);

  const MIN_SEARCH_LENGTH = 3;
  const { search, ...filtersWithoutSearch } = filters;
  const searchValue = search != null && search.length >= MIN_SEARCH_LENGTH ? search : "";

  const [data, { isLoading, reload, setVariables }] = useQuery(FlemingTransactionListPageDocument, {
    accountId,
    first: NUM_TO_RENDER,
    filters: {
      ...filtersWithoutSearch,
      search: searchValue,
      paymentProduct,
      status: filters.status ?? DEFAULT_STATUSES,
    },
    exportFilters: {
      ...filtersWithoutSearch,
      search: searchValue,
      paymentProduct,
      status: EXPORT_STATUSES,
    },
    canQueryCardOnTransaction,
    canViewAccount,
  });

  const [activeTransactionId, setActiveTransactionId] = useState<string | null>(null);
  const [transactionUpdating, setTransactionUpdating] = useState<boolean>(false);
  const [exportRequested, setExportRequested] = useState<boolean>(false);
  const [exportType, setExportType] = useState<ExportName | undefined>(undefined);
  const [exportCursor] = useState<string | undefined>(undefined);

  const transactionsListCount = data.match({
    NotAsked: () => null,
    Loading: () => null,
    Done: result =>
      result.match({
        Error: _ => {
          return null;
        },
        Ok: data => {
          return data.account?.transactionsInfo?.totalCount ?? 0;
        },
      }),
  });

  const canExportMT940 = (transactionsListCount) ? (transactionsListCount > 0) : false;
  const canExportCSV = (transactionsListCount) ? (transactionsListCount > 0) : false;

  const panelRef = useRef<FocusTrapRef | null>(null);

  const onActiveRowChange = useCallback(
    (element: HTMLElement) => panelRef.current?.setInitiallyFocusedElement(element),
    [],
  );
  const extraInfo = data.match({
    NotAsked: () => null,
    Loading: () => null,
    Done: result =>
      result.match({
        Error: _ => {
          return null;
        },
        Ok: data => {
          return {
            IBAN: data.account?.IBAN ?? undefined,
            accountType: data.account?.holder.info.type,
          };
        },
      }),
  });
  const { IBAN } = extraInfo ? extraInfo : { IBAN: undefined };

  const exportRequest = (exportName: ExportName) => {
    setExportType(exportName);
    setExportRequested(true)
  };

  const exportVariables = {
    accountId,
    after: exportCursor,
    first: NUM_TO_EXPORT,
    filters: {
      ...filters,
      paymentProduct,
      status: filters.status ?? DEFAULT_STATUSES,
    },
    canQueryCardOnTransaction,
    canViewAccount,
  };

  return (
    <ResponsiveContainer style={styles.root} breakpoint={breakpoints.large}>
      {({ large }) => (
        <>
        {exportRequested && <TransactionExporter IBAN={IBAN} exportName={exportType} variables={exportVariables} setExportRequested={setExportRequested} />}

          <Box style={[styles.filters, large && styles.filtersLarge]}>
            <TransactionListFilter
              filters={filters}
              onChange={({ status, ...filters }) =>
                Router.push("AccountTransactionsListRoot", {
                  accountMembershipId,
                  transactionStatus: status,
                  ...filters,
                })
              }
              onRefresh={() => {
                reload();
              }}
              large={large}
            >
              {accountStatementsVisible ? (
                <>
                  <LakeButton
                    onPress={() =>
                      Router.push("AccountTransactionsListStatementsRoot", {
                        accountMembershipId,
                      })
                    }
                    size="small"
                    color="current"
                    icon="arrow-download-filled"
                    ariaLabel={t("accountStatements.title")}
                  >
                    {large ? t("accountStatements.title") : null}
                  </LakeButton>

                  <Space width={16} />

                  <ExportChooser
                    canExport={{
                      [ExportName.MT940]: canExportMT940,
                      [ExportName.CSV]: canExportCSV,
                    }}
                    exports={{ [ExportName.MT940]: true, [ExportName.CSV]: true }}
                    label={t("accountStatementsExport.title")}
                    title={t("accountStatementsExport.title")}
                    onExport={(e: ExportName) => exportRequest(e)}
                    large={large}
                  />
                </>
              ) : null}
            </TransactionListFilter>
          </Box>

          {data.match({
            NotAsked: () => null,
            Loading: () => (
              <PlainListViewPlaceholder
                count={NUM_TO_RENDER}
                rowVerticalSpacing={0}
                groupHeaderHeight={48}
                headerHeight={48}
                rowHeight={48}
              />
            ),
            Done: result =>
              result.match({
                Error: error => <ErrorView error={error} />,
                Ok: data => (
                  <Connection connection={data.account?.transactions}>
                    {transactions => {
                      return (
                      <>
                        <TransactionList
                          withStickyTabs={true}
                          transactions={transactions?.edges ?? []}
                          getRowLink={({ item }) => (
                            <Pressable onPress={() => setActiveTransactionId(item.id)} />
                          )}
                          pageSize={NUM_TO_RENDER}
                          activeRowId={activeTransactionId ?? undefined}
                          onActiveRowChange={onActiveRowChange}
                          loading={{
                            isLoading,
                            count: 2,
                          }}
                          onEndReached={() => {
                            if (data.account?.transactions?.pageInfo.hasNextPage ?? false) {
                              setVariables({
                                after: transactions?.pageInfo.endCursor ?? undefined,
                              });
                            }
                          }}
                          renderEmptyList={() =>
                            hasFilters ? (
                              <FixedListViewEmpty
                                icon="lake-transfer"
                                borderedIcon={true}
                                title={t("transansactionList.noResults")}
                                subtitle={t("common.list.noResultsSuggestion")}
                              />
                            ) : (
                              <FixedListViewEmpty
                                icon="lake-transfer"
                                borderedIcon={true}
                                title={t("transansactionList.noResults")}
                              />
                            )
                          }
                        />

                        {match(route)
                          .with(
                            { name: "AccountTransactionsListDetail" },
                            ({ params: { transactionId } }) => (
                              <RightPanel
                                visible={true}
                                onPressClose={() => {
                                  setActiveTransactionId(null);
                                  Router.push("AccountTransactionsListRoot", {
                                    accountMembershipId,
                                  });
                                }}
                              >
                                {({ large }) => (
                                  <>
                                    <Box style={large ? styles.buttonLarge : styles.button}>
                                      <LakeButton
                                        mode="tertiary"
                                        icon="lake-close"
                                        ariaLabel={t("common.closeButton")}
                                        onPress={() => {
                                          setActiveTransactionId(null);
                                          Router.push("AccountTransactionsListRoot", {
                                            accountMembershipId,
                                          });
                                        }}
                                        children={null}
                                      />
                                    </Box>

                                    <TransactionDetail
                                      transactionUpdating={transactionUpdating}
                                      setTransactionUpdating={setTransactionUpdating}
                                      accountId={accountId}
                                      userId={userId}
                                      accountMembershipId={accountMembershipId}
                                      large={large}
                                      transactionId={transactionId}
                                      canQueryCardOnTransaction={canQueryCardOnTransaction}
                                      canViewAccount={canViewAccount}
                                    />

                                    <Space height={24} />
                                  </>
                                )}
                              </RightPanel>
                            ),
                          )
                          .otherwise(() => {
                            return (
                            <ListRightPanel
                              ref={panelRef}
                              keyExtractor={item => item.id}
                              activeId={activeTransactionId}
                              onActiveIdChange={setActiveTransactionId}
                              onClose={() => setActiveTransactionId(null)}
                              items={transactions?.edges.map(item => item.node) ?? []}
                              render={(item, large) => (
                                <TransactionDetail
                                  transactionUpdating={transactionUpdating}
                                  setTransactionUpdating={setTransactionUpdating}
                                  accountId={accountId}
                                  transactionId={item.id}
                                  userId={userId}
                                  large={large}
                                  accountMembershipId={accountMembershipId}
                                  transaction={item}
                                  canQueryCardOnTransaction={false}
                                  canViewAccount={false}
                                />
                              )}
                              closeLabel={t("common.closeButton")}
                              previousLabel={t("common.previous")}
                              nextLabel={t("common.next")}
                            />
                          )})}
                      </>
                    )}
              }</Connection>
                ),
              }),
          })}
        </>
      )}
    </ResponsiveContainer>
  );
};
