import { Box } from "fleming-lake/src/components/Box";
import { LakeButton } from "fleming-lake/src/components/LakeButton";
import { LakeText } from "fleming-lake/src/components/LakeText";
import { ResponsiveContainer } from "fleming-lake/src/components/ResponsiveContainer";
import { Space } from "fleming-lake/src/components/Space";
import { TabView } from "fleming-lake/src/components/TabView";
import { commonStyles } from "fleming-lake/src/constants/commonStyles";
import { breakpoints, colors } from "fleming-lake/src/constants/design";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { PreparedTransferList } from "../components/PreparedTransferList";
import { RecurringTransferList } from "../components/RecurringTransferList";
import { t } from "../utils/i18n";
import { Router, paymentRoutes } from "../utils/routes";

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingHorizontal: 24,
  },
  containerDesktop: {
    paddingTop: 40,
    paddingHorizontal: 40,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

type Props = {
  accountId: string;
  accountMembershipId: string;
  transferCreationVisible: boolean;
  canQueryCardOnTransaction: boolean;
  canViewAccount: boolean;
  userId: string;
  canInitiatePayments: boolean;
};

export const TransferPage = ({
  accountId,
  accountMembershipId,
  transferCreationVisible,
  canQueryCardOnTransaction,
  canViewAccount,
  userId,
  canInitiatePayments,
}: Props) => {
  const route = Router.useRoute(paymentRoutes);

  return (
    <ResponsiveContainer breakpoint={breakpoints.large} style={commonStyles.fill}>
      {({ small }) => (
        <>
          {transferCreationVisible ? (
            <Box direction="row">
              <ResponsiveContainer
                breakpoint={breakpoints.small}
                style={[
                  styles.buttonContainer,
                  commonStyles.fill,
                  small ? styles.container : styles.containerDesktop,
                ]}
              >
                {({ small }) => (
                  <>
                    <LakeButton
                      grow={small}
                      onPress={() =>
                        Router.push("AccountPaymentsNew", { accountMembershipId, type: "transfer" })
                      }
                      icon="add-circle-filled"
                      size="small"
                      color="current"
                    >
                      {t("transfer.newTransfer")}
                    </LakeButton>

                    <Space width={16} />

                    <LakeButton
                      onPress={() =>
                        Router.push("AccountPaymentsNew", {
                          accountMembershipId,
                          type: "recurring",
                        })
                      }
                      icon="add-circle-filled"
                      size="small"
                      color="current"
                    >
                      {t("transfer.newRecurring")}
                    </LakeButton>
                  </>
                )}
              </ResponsiveContainer>
            </Box>
          ) : null}

          <Space height={24} />

          <TabView
            padding={small ? 24 : 40}
            sticky={true}
            hideIfSingleItem={false}
            tabs={[
              {
                label: t("transfer.tabs.preparedTransfer"),
                url: Router.AccountPaymentsPreparedTransfers({ accountMembershipId }),
              },
              {
                label: t("transfer.tabs.recurringTransfer"),
                url: Router.AccountPaymentsRecurringTransferList({ accountMembershipId }),
              },
            ]}
            otherLabel={t("common.tabs.other")}
          />

          <Space height={24} />

          {match(route)
            .with(
              { name: "AccountPaymentsRecurringTransferList" },
              { name: "AccountPaymentsRecurringTransferDetailsArea" },
              () => (
                <RecurringTransferList
                  accountId={accountId}
                  accountMembershipId={accountMembershipId}
                  canQueryCardOnTransaction={canQueryCardOnTransaction}
                  canViewAccount={canViewAccount}
                />
              ),
            )
            .with(
              { name: "AccountPaymentsRoot" },
              { name: "AccountPaymentsPreparedTransfers" },
              { name: "AccountPaymentsPreparedTransferDetailsArea" },
              ({ params }) => {
                return (
                  <PreparedTransferList
                    canInitiatePayments={canInitiatePayments}
                    userId={userId}
                    accountId={accountId}
                    accountMembershipId={accountMembershipId}
                    canQueryCardOnTransaction={canQueryCardOnTransaction}
                    canViewAccount={canViewAccount}
                    params={params}
                  />
                );
              },
            )
            .otherwise(() => (
              <ErrorView />
            ))}

          <Space height={12} />

          <LakeText align="center" variant="smallRegular" color={colors.gray[700]}>
            {t("transfer.disclaimer.swan")}
          </LakeText>
        </>
      )}
    </ResponsiveContainer>
  );
};
