import dayjs from "dayjs";
import { locale } from "./i18n";

export const encodeDateTime = (date: string, time: string) => {
  const dateTime = dayjs(`${date} ${time}`, `${locale.dateFormat} ${locale.timeFormat}`);
  return dateTime.isValid() ? dateTime.toISOString() : "";
};

export const isToday = (date: string) => {
  const today = dayjs().format(locale.dateFormat);
  return date === today;
};


export function splitIsoDateTime(isoDateTime: string): { date: string, time: string } {
  // Parse the ISO string into a Date object
  const date = new Date(isoDateTime);

  // Extracting date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  // Extracting time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format date and time
  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return { date: formattedDate, time: formattedTime };
}
